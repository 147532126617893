import { lazy, React, useEffect, useState, Suspense } from 'react';
import Navlogo from '../../assets/img/nav_logo.svg';
import ChartImg from '../../assets/img/chart.svg';
import walletImg from '../../assets/img/wallet.svg';
import whaleTtail from '../../assets/img/whale-tail.svg';
import NavaddImg from '../../assets/img/nav_add.svg';
import ToolImg from '../../assets/img/tool.svg';
import NavhatImg from '../../assets/img/nav_hat.svg';
import NotifyarrowImg from '../../assets/img/notify_arrow.svg';
import NavnsettingImg from '../../assets/img/nav_setting.svg';
import AccountchangeImg from '../../assets/img/account_change.svg';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useLocation, useNavigate } from 'react-router';
import authService from '../../Services/auth.service';
import { Link } from "react-router-dom";
import Pusher from "pusher-js";
import { useSelector, useDispatch } from "react-redux";
import { Store } from 'react-notifications-component';
import { setAccountData } from '../../store/dashboardSlice';
import { setPendingPayment } from '../../store/paymentSlice';
import { setRemainingSlot } from '../../store/walletsSlice';
import { setDiscount, setSubscriptionPrice } from "../../store/packageSlice";
import CircleLoader from '../../ReusableComponent/CircleLoader';

const PendingPaymentFromDiscount = lazy(() => import('../Payment/PendingPaymentFromDiscount'));
const PendingPayment = lazy(() => import('../Payment/PendingPayment'));
const AddTime = lazy(() => import('./Models/AddTime'));
const DiscountBanner = lazy(() => import('./DiscountBanner'));
const AddUserToAccount = lazy(() => import('./Models/AddUserToAccount'));
const Setting = lazy(() => import('./Models/Setting'));

export default function PersonalHeader({ showDashboardLink }) {
    const location = useLocation();
    const navigate = useNavigate();
    const [notificationCount, setNotificationCount] = useState()
    const [isActive, setIsActive] = useState("false");
    const [flag, setflag] = useState(false);
    const [showloader, setLoader] = useState(false);
    const [openPendingPayment, setOpenPendingPayment] = useState(false);
    const accountID = useSelector((state) => state.dashboard.accountID);
    const accountData = useSelector((state) => state.dashboard.accountData);
    const has_multi_account = useSelector((state) => state.dashboard.has_multi_account);
    const discount = useSelector((state) => state.package.discount);
    const dispatch = useDispatch();

    useEffect(() => {
        if (accountID === null)
            return;

        // Pusher subscription for payment checking
        Pusher.logToConsole = true;
        let pusher = new Pusher('16ee9c30a883feed0cfd', {
            cluster: 'us3'
        });
        let channel = pusher.subscribe('payment.checking.' + accountID);
        channel.bind('payment-success', function (data) {
            Store.addNotification({
                title: "Success!",
                message: "Payment successfully complete",
                type: "info",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 10000,
                    onScreen: true
                }
            });
            dispatch(setAccountData(data.account_data));
            dispatch(setRemainingSlot(data.remaining_slot));
            dispatch(setPendingPayment(null));
        });
    }, [accountID]);

    useEffect(() => {
        authService.NotificationApi().then((response) => {
            setNotificationCount(response.data.data)
        })
    }, [])

    const hanldeToogleForToolkit = () => {
        setIsActive(current => !current);
    }

    const handleLogOut = () => {
        setLoader(true)
        setflag(true)
        authService.LogOutApi().then((response) => {
            localStorage.clear();
            navigate('/crypto/login');
        }).finally(() => setLoader(false))
    }

    useEffect(() => {
        authService.SubscriptionPrice('Personal_Yearly').then((response) => {
            dispatch(setSubscriptionPrice(response.data.data.common_price));
            dispatch(setDiscount(response.data.data.discount));
        }).catch((e) => {
            if (e.response.status && e.response.status === 401)
                navigate("/crypto/login")
        })
    }, []);

    return (
        <>
            <header className="nav_wrapper">
                <div className="container">
                    <div className="nav_inner">
                        <div className="nav_left">
                            <Link to="/crypto/dashboard"> <img src={Navlogo} alt="nav_logo.svg" /></Link>
                        </div>
                        <div className="nav_right">
                            <div className={`right_inner justify-content-end ${location.pathname === "wallets_management" ? "w-100" : ""} `}>
                                <div className="nav_icons">
                                    {((accountData !== undefined && accountData?.current_user?.selectedAccount !== null)) ? <>
                                        <div className="img_wrap">
                                            <a href="" data-bs-toggle="modal" data-bs-target="#usersModal">
                                                <img src={NavaddImg} alt="navaddimage" /></a>
                                        </div>
                                        <div className="position-relative w-100" onClick={hanldeToogleForToolkit}>
                                            <div className="img_wrap tool_icon" id="tool_drop" >
                                                <a><img src={ToolImg} alt="tool.img" className="" /></a>
                                            </div>
                                            <ul className={isActive ? 'ul_open_close' : 'ul_open'} id="ul_drop">
                                                <li className="m-0">
                                                    {
                                                        accountData.status === 'Active' ? (
                                                            <Link to="/crypto/chart">
                                                                <img src={ChartImg} alt="chart.svg" />
                                                            </Link>
                                                        ) : (
                                                            <>
                                                                <img src={ChartImg} alt="chart.svg" data-tooltip-id="ChartPage" data-tooltip-content="Subscribe to access this feature" />
                                                            </>
                                                        )
                                                    }
                                                </li>
                                                <li>
                                                    <a href="#" data-tooltip-id="my-element3" data-tooltip-content="Comming Soon">
                                                        <img src={walletImg} alt="wallet.svg" />
                                                    </a>
                                                </li>

                                                <li className="popover1 m-0">
                                                    <a className="popover__trigger" ><img src={whaleTtail} alt="whaleTtail" data-tooltip-id="my-element" data-tooltip-content="Comming Soon" /></a>
                                                    <ul className="popover__menu whale_poper">
                                                        <li className="popover__menu-item">
                                                            <button>Coming Soon</button>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                        <ReactTooltip id="my-element" place="bottom" className='cstkanda' />
                                        <ReactTooltip id="ChartPage" place="bottom" className='cstkanda' />


                                        <div className="img_wrap">
                                            <a href="https://www.loom.com/share/9aa6135d2e3e40f6b824aa29acd3d799?sid=4ad49a8c-2761-43fc-b3c3-1203a0d1aae9" target="_blank">
                                                <img src={NavhatImg} alt="Navhat" />
                                            </a>
                                        </div>
                                        <ReactTooltip id="my-element3" place="bottom" className='cstkanda' />

                                        <div className="dropdown img_wrap">
                                            <a id="dropdownMenuLink" className="notification" data-bs-toggle="dropdown" aria-expanded="false">
                                                <span className="badge">{notificationCount === 0 ? "" : notificationCount}</span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" fill="currentColor" className="bi bi-bell-fill svgBellIcon" viewBox="0 0 16 16">
                                                    <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
                                                </svg>
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                <li>
                                                    <a className="dropdown-item header_sec flex_align" href="#">
                                                        <h4>Notifications: <span>{notificationCount} New</span></h4>
                                                    </a>
                                                </li>
                                                <li>
                                                    <div className="wallet_detail">
                                                        {notificationCount > 0 && <p>
                                                            You have <span>Unknown</span> Wallets that need to be identified.
                                                            Visit your <span>wallet labels</span> now to update them.
                                                        </p>}

                                                        {notificationCount === 0 && <p>Your <span>wallets</span> are all labeled period</p>}
                                                        <Link to="/crypto/wallets_management" className="wallet_btn flex_align">Visit Wallet Labels</Link>
                                                    </div>
                                                </li>
                                                <div className="notify_arrow">
                                                    <img src={NotifyarrowImg} alt="NotifyarrowImg" />
                                                </div>
                                            </ul>
                                        </div>

                                        <div className="img_wrap">
                                            <a href="" data-bs-toggle="modal" data-bs-target="#ModalSetting">
                                                <img src={NavnsettingImg} alt="nav setting" />
                                            </a>
                                        </div>

                                        {has_multi_account &&
                                            <div className="img_wrap">
                                                <Link to="/crypto/multiple_accounts">
                                                    <img src={AccountchangeImg} alt="Accountchange" />
                                                </Link>
                                            </div>
                                        }
                                        {
                                            showDashboardLink &&
                                            <Link to="/crypto/dashboard" className="btn logout_btn dash_btn ms-0 cstm-cls">Dashboard</Link>
                                        }
                                    </> : null
                                    }
                                </div>
                                <button className="btn logout_btn" onClick={handleLogOut} disabled={flag}>Logout</button>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {
                (discount !== null && discount !== undefined) && (
                    <>
                        <Suspense>
                            <DiscountBanner />
                        </Suspense>
                        <Suspense>
                            <PendingPaymentFromDiscount />
                        </Suspense>
                    </>
                )
            }
            <Suspense>
                <Setting />
            </Suspense>
            <Suspense>
                <AddUserToAccount />
            </Suspense>
            <Suspense>
                <AddTime setOpenPendingPayment={setOpenPendingPayment} />
            </Suspense>
            <Suspense>
                <PendingPayment openModal={openPendingPayment} />
            </Suspense>
            {showloader ? <CircleLoader /> : ""}
        </>
    )
}

export function PersonalMinHeader() {
    const [flag, setflag] = useState(false);
    const [showloader, setLoader] = useState(false);
    const navigate = useNavigate();

    const handleLogOut = () => {
        setLoader(true)
        setflag(true)
        authService.LogOutApi().then((response) => {
            localStorage.clear();
            navigate('/crypto/login');
        }).finally(() => setLoader(false))

    }

    return (
        <>
            <header className="nav_wrapper">
                <div className="container">
                    <div className="nav_inner">
                        <div className="nav_left">
                            <Link to="/crypto/dashboard"> <img src={Navlogo} alt="nav_logo_svg" /></Link>
                        </div>
                        <div className="nav_right">
                            <div className="right_inner justify-content-end">
                                <button className="btn logout_btn" onClick={handleLogOut} disabled={flag}>Logout</button>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {showloader ? <CircleLoader /> : ""}
        </>
    )
}