import React from 'react'
import { FiArrowUpCircle } from 'react-icons/fi';

const arrowStyle = { fontSize: "44px", color: "#77cad2", cursor: "pointer" }
export const BottamArrow = (props) => {
    return (
        <>
            <div className="progress-wrap active-progress" onClick={props.goToTop}>
                <FiArrowUpCircle className="progress-icon" style={arrowStyle} />
            </div>
        </>
    )
}
