import { REACT_APP_SITE_KEY, REGEMAIL } from "../../Constants/constant";
import React, { createRef, lazy, useEffect, useState, Suspense } from "react";
import authService from "../../Services/auth.service";
import swal from "sweetalert";

// const ReCAPTCHA = lazy(() => import('react-google-recaptcha'));

export default () => {
    const captchaRef = createRef();
    const [isSubmit, setIsSubmit] = useState(false);
    const [contactFormInputes, setcontactFormInputes] = useState({
        cotactUserName: "",
        contactMessage: "",
        contactEmail: "",
    })
    const [errorContactForm, setErrorContactForm] = useState({
        cotactUserName: "",
        contactMessage: "",
        contactEmail: "",
    })

    useEffect(() => {
        if (Object.keys(errorContactForm).length === 0 && isSubmit) {
            console.log(contactFormInputes);
        }
    }, []);

    const validate = () => {
        if (!contactFormInputes.cotactUserName) {
            errorContactForm.cotactUserName = "Enter name";
        }
        if (!contactFormInputes.contactEmail) {
            errorContactForm.contactEmail = "Enter email";
        }

        return errorContactForm;
    };

    const handlecontactusFormInputs = (e) => {
        const { name, value } = e.target
        setcontactFormInputes({ ...contactFormInputes, [name]: value })
        switch (name) {
            case 'cotactUserName':
                errorContactForm.cotactUserName = value.length > 0 ? "" : "Enter name";
                break;
            case 'contactEmail':
                errorContactForm.contactEmail = REGEMAIL.test(value) ? "" : "Enter Valid email";
                break;
        }
        setErrorContactForm(errorContactForm)
    }

    const submitContactForm = (e) => {
        e.preventDefault();
        setErrorContactForm(validate(contactFormInputes));
        setIsSubmit(true);

        const messagePayload = {
            name: contactFormInputes.cotactUserName,
            email: contactFormInputes.contactEmail,
            message: contactFormInputes.contactMessage,
            // recaptcha_response: captchaRef.current.getValue(),
        }

        if (contactFormInputes.contactEmail !== "" && contactFormInputes.cotactUserName !== "") {
            authService.ContactApi(messagePayload).then((response) => {
                swal({
                    title: "Success",
                    text: "Thank you for contacting, Definance team will contact You Soon..",
                    icon: "success",
                })
            })
            setcontactFormInputes({
                cotactUserName: "",
                contactMessage: "",
                contactEmail: "",
            })
        }
    }

    return (
        <div className="contact_box">
            <h2>Contact Us</h2>
            <div className='custom_input'>
                <div className="input_wrap">
                    <label htmlFor="cotactUserName">Name</label>
                    <input type="text" name="cotactUserName" id="cotactUserName" className="form-control" value={contactFormInputes.cotactUserName} onChange={handlecontactusFormInputs} aria-labelledby="cotactUserName" />
                </div>
                <span className="red-error contact">{errorContactForm.cotactUserName}</span>
            </div>
            <div className='custom_input'>
                <div className="input_wrap">
                    <label htmlFor="contactEmail">Email</label>
                    <input type="email" name='contactEmail' id="contactEmail" className="form-control" value={contactFormInputes.contactEmail} onChange={handlecontactusFormInputs} aria-labelledby="contactEmail" />
                </div>
                <span className="red-error contact">{errorContactForm.contactEmail}</span>
            </div>
            <div className='custom_input'>
                <div className="input_wrap">
                    <label htmlFor="contactMessage">Message</label>
                    <textarea name="contactMessage" id="contactMessage" cols="30" rows="10" className="form-control" value={contactFormInputes.contactMessage} onChange={handlecontactusFormInputs} aria-labelledby="contactMessage"></textarea>
                </div>
                {/* <div className='flex_align mb-2'>
                    <Suspense>
                        <ReCAPTCHA sitekey={REACT_APP_SITE_KEY} ref={captchaRef} />
                    </Suspense>
                </div> */}
                <button className="btn send_message flex_align" onClick={submitContactForm} >Send Message</button>
            </div>
        </div>
    );
}