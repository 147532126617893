import React, { Suspense } from 'react';
import Navlogo from '../assets/img/new_transparent_image-03.png';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Link } from "react-router-dom";
import Meta from './Meta'

const Header = () => {
    return (<>
        <header className="nav_wrapper index_nav cstm-top-zero">
            <Suspense>
                <Meta />
            </Suspense>
            <div className="container">
                <div className="nav_inner">
                    <div className="nav_left">
                        <Link to="/">
                            <img src={Navlogo} alt="DeFinance Logo" width="100%" height="100%" />
                        </Link>
                    </div>
                    <div className="nav_right">
                        <ul className="nav nav-tabs" data-background-color="orange">
                            <li className="nav-item">
                                <Link to="/crypto" className="nav-link active">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/crypto/login" className="nav-link">Login</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/crypto/registration" className="nav-link">Registration</Link>
                            </li>
                        </ul>
                        <ul className="nav index_tabs">
                            <li className="nav-item">
                                <AnchorLink className="nav-link" href="#anchor_about_us">About Us</AnchorLink>
                            </li>
                            <li className="nav-item">
                                <AnchorLink className="nav-link" href="#anchoor_our_service">Services</AnchorLink>
                            </li>
                            <li className="nav-item">
                                <AnchorLink className="nav-link" href="#anchor_our_price">Pricing</AnchorLink>
                            </li>
                            <li className="nav-item">
                                <AnchorLink className="nav-link" href="#anchor_crypto_faq">FAQ</AnchorLink>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="https://definance.one/blog/">Blog</a>
                            </li>
                            <li className="nav-item">
                                <AnchorLink className="nav-link active" href="#anchor_our_contact">Contact Us</AnchorLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    </>)
}

export default Header