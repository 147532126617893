import React, {lazy, Suspense} from "react";
import { AuthProvider } from "../context/AuthContext";
import { Provider } from 'react-redux';
import store from "../store/mainStore";

const Chart = lazy(() => import('../Component/WalletsManagement/Charts'));

function ChartPage () {
    return (
        <Provider store={store}>
            <AuthProvider>
                <Suspense>
                    <Chart />
                </Suspense>
            </AuthProvider>
        </Provider>
    );
}

export default ChartPage;