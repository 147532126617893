import React from 'react'
import NavImg from '../../../../assets/img/nav_logo.svg'
// import NavImg from '../../../../assets/img/new_transparent_image-01.png'

import { Link, useLocation } from "react-router-dom";
import Meta from '../Meta';

const MainHeader = () => {
    const location = useLocation();
    return (
        <>
            <header className="nav_wrapper">
                <Meta />
                <div className="container">
                    <div className="nav_inner">
                        <div className="nav_left">
                            <a href="#"><img src={NavImg} alt="NavImg" width="100%" height="100%" /></a>
                        </div>
                        <div className="nav_right">
                            <ul className="nav nav-tabs" data-background-color="orange">
                                <li className="nav-item">
                                    <Link to="/crypto" className={`nav-link ${location.pathname === "/crypto" ? "active" : ""}`}>Home</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/crypto/login" className={`nav-link ${location.pathname === "/crypto/login" ? "active" : ""}`}>Login</Link>
                                </li>
                                {location.pathname !== "/crypto/forget-pass" ? <>
                                    <li className="nav-item">
                                        <Link to="/crypto/registration" className={`nav-link ${location.pathname === "/crypto/registration" ? "active" : ""}`}>Registration</Link>
                                    </li>
                                </> : ""}
                                {location.pathname === "/crypto/forget-pass" ? <>
                                    <li className="nav-item">
                                        <Link to="/crypto/forget-pass" className={`nav-link ${location.pathname === "/crypto/forget-pass" ? "active" : ""}`}>Forgot Password</Link>
                                    </li>
                                </> : ""}
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default MainHeader