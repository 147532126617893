import {createSlice} from '@reduxjs/toolkit';

export const dashboardSlice= createSlice({
    name: 'dashboard',
    initialState: {
        username: '',
        recovery_email: null,
        accountName: '',
        accountID: null,
        accountData: {},
        has_multi_account: false,
        data: []
    },
    reducers: {
        setUsername: (state, action) => {
            state.username= action.payload
        },
        setRecoveryEmail: (state, action) => {
            state.recovery_email= action.payload
        },
        setAccountName: (state, action) => {
            state.accountName= action.payload
        },
        setAccountID: (state, action) => {
            state.accountID= action.payload
        },
        setAccountData: (state, action) => {
            state.accountData= action.payload
        },
        setHasMultiAccount: (state, action) => {
            state.has_multi_account= action.payload
        },
        setDashboardData: (state, action) => {
            state.data= action.payload
        }
    }
})

export const { setDashboardData, setAccountName, setUsername, setRecoveryEmail, setAccountID, setAccountData, setHasMultiAccount } = dashboardSlice.actions;

export default dashboardSlice.reducer;