import {createContext, React, useEffect} from 'react';
import { useNavigate } from 'react-router';
import authService from '../Services/auth.service';
import {setListing, setRemainingSlot} from "../store/walletsSlice";
import { setPageLoading } from "../store/pageSlice";
import {
    setUsername,
    setRecoveryEmail,
    setAccountData,
    setAccountID,
    setHasMultiAccount,
    setAccountName
} from "../store/dashboardSlice";
import { useDispatch } from "react-redux";
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const dispatch= useDispatch();

    useEffect(() => {
        authService.AccountInformations().then((response) => {
            dispatch(setAccountData(response.data.data.current_user?.has_access));
            dispatch(setAccountID(response.data.data.current_user?.has_access?.accountID));
            dispatch(setAccountName(response.data.data.current_user?.has_access?.account_name));
            dispatch(setHasMultiAccount(response.data.data.current_user.has_multi_account));
            dispatch(setUsername(response.data.data.current_user?.username));
            dispatch(setRecoveryEmail(response.data.data.current_user?.recovery_email));
            if (response.data.data.current_user?.has_access?.status === 'Active')
                getWallets();
            else
                dispatch(setPageLoading(false));
        }).catch((e) => {
            if (e.response.status && e.response.status === 401)
                navigate("/crypto/login");
            dispatch(setPageLoading(false));
        })
    }, []);

    const getWallets= ()  => {
        authService.GetAllWallets().then((response) => {
            if (response.status === 200) {
                dispatch(setListing(response.data.data.wallets));
                dispatch(setRemainingSlot(response.data.data.remaining_slot));
            }
        }).finally(() => dispatch(setPageLoading(false)))
    }

    return (
        <>
            <ReactNotifications />
            <AuthContext.Provider value={{}}>
                {children}
            </AuthContext.Provider>
        </>
    )
}