import { BsChatLeftText } from 'react-icons/bs'

const LiveChatButton = () => {
    return (
        <>
            <div className="live_wrap">
                <a href="https://discord.gg/9Hu6KJEa3q" target="_blank" className="btn live_chat text-center  d-flex flex_align ">
                    <span>Live Chat </span>
                    <BsChatLeftText />
                </a>
            </div>
        </>
    )
}

export default LiveChatButton