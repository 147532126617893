import {configureStore} from '@reduxjs/toolkit';
import walletsSlice from "./walletsSlice";
import pageSlice from "./pageSlice";
import paymentSlice from "./paymentSlice";
import packageSlice from "./packageSlice";
import dashboardSlice from "./dashboardSlice";
import usersSlice from "./usersSlice";

export default configureStore({
    reducer: {
        wallets: walletsSlice,
        page: pageSlice,
        payment: paymentSlice,
        package: packageSlice,
        dashboard: dashboardSlice,
        users: usersSlice
    },
});