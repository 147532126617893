import React from "react";
import { Helmet } from "react-helmet";

const Meta = () => {
    return (
        <Helmet>
            <title>Partner with DeFinance for Business Planning and Financial Forecasting</title>
            <meta name='description' content='Take control of your business finances with DeFinance. Create Pro Forma Financial Statements, Financial Planning & Analysis Models. We love Crypto Consulting for Startups!' />
            <meta name="keywords" content="Pro Forma, Pro Forma Financial Statements, Financial Forecast, Business Plan Financial Projections, Financial Planning and Forecasting, Financial Projections for Startups, Small Business Finance, excel sheet work, Financial Analysis, FP&A, Financial Planning and Analysis, Financial Reporting, Financial Statements, P&L Forecast, Crypto Business, Starting a Crypto Business, Business Consulting, small business consulting, small business consulting services, business partnering, reliable business partnering, cryptocurrency business, cryptocurrency start-ups, crypto start-ups, crypto consulting, crypto consulting services, cryptocurrency consulting firms, cryptocurrency consulting services" />
        </Helmet>
    )
}
export default Meta;