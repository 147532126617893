import axios from "axios";
import authHeader from "./auth.header";
import { commonError, commonWarning } from "../ReusableComponent/Common";

const API_URL = "https://backend.definance.one/api/v1/";
//const API_URL = "http://127.0.0.1:8000/api/v1/";

let reqInstance;
if (localStorage.getItem('token')) {
    reqInstance = axios.create({ headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
} else {
    reqInstance = axios.create();
}

// Global error management
reqInstance.interceptors.response.use((response) => response, (error) => {
    if (error.response.status === 400 || error.response.status === 403)
        commonWarning("Warning", error.response.data.message);
    else
        commonError();
    throw error;
});


const refreshReqInstance = () => {
    reqInstance = axios.create({ headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
}

const regsiterUser = (data) => {
    return reqInstance.post(API_URL + "registration", data,)
}

const loginUser = (data) => {
    return reqInstance.post(API_URL + "login", data,)
}

const ForgotPassword = (data) => {
    return reqInstance.post(API_URL + "forget_password", data,)
}

const ResetPassword = (data) => {
    return reqInstance.post(API_URL + "reset_password", data,)
}

const CheckEmail = (data) => {
    return reqInstance.post(API_URL + "checkemail", data)
}

const CheckUsername = (data) => {
    return reqInstance.post(API_URL + "checkUsername", data)
}

const AccountInformations = () => {
    return reqInstance.get(API_URL + "accountInformations")
}

const GetWalletLiting = () => {
    return reqInstance.get(API_URL + "getwallet")
}

const AddWalletData = (data) => {
    return reqInstance.post(API_URL + "addwallet", data,)
}

const EditWalletById = (data1, data2) => {
    return reqInstance.get(API_URL + `getwalletbyid?theidentifier=${data1}&list_number=${data2}`)
}

const EditWalletData = (data) => {
    return reqInstance.post(API_URL + "editwallet", data,)
}

const DeleteWalletData = (data) => {
    return reqInstance.post(API_URL + "deletewallet", data,)
}

const AddUserData = (data) => {
    return reqInstance.post(API_URL + "adduser", data,)
}

const UpdateUserName = (data) => {
    return reqInstance.post(API_URL + "updateusername", data,)
}

const UpdateEmail = (data) => {
    return reqInstance.post(API_URL + "updateemail", data,)
}

const GetUserListings = () => {
    return reqInstance.get(API_URL + "getusers")
}

const ChangeUserPassword = (data) => {
    return reqInstance.post(API_URL + "changepassword", data,)
}

const DeleteUser = (data) => {
    return reqInstance.post(API_URL + "deleteUser", data,)
}

const NotificationApi = () => {
    return reqInstance.post(API_URL + "notification")
}

const GetPaymentCryptos = () => {
    return reqInstance.get(API_URL + "payment/cryptos")
}

const GetPendingPayments = () => {
    return reqInstance.post(API_URL + "getpendingpayments")
}

const GetCryptoConverted = (crypto_label) => {
    return reqInstance.get(API_URL + "payment/conversion?crypto_label=" + crypto_label)
}

const SendPayments = (data) => {
    return reqInstance.post(API_URL + "sendpayments", data,)
}

const UpdatePayment = (data) => {
    return reqInstance.post(API_URL + "payment/updatewallet", data,)
}

const CancelPayment = () => {
    return reqInstance.post(API_URL + "payment/cancel")
}

const DashbordApiForAccStatus = () => {
    return reqInstance.get(API_URL + "dashboard")
}

const SubscriptionPrice = (service) => {
    return reqInstance.get(API_URL + "subscription/price?service=" + service)
}

// const GetScanWalletApi = (data) => {
//     return reqInstance.get(API_URL + "getbscscanwallets", {
//         params: data
//     })
// }

const GetScanByWallet = (data) => {
    return reqInstance.get(API_URL + "getbscscanbywallet", {
        params: data
    })
}

const CheckWalletAddress = (data) => {
    return reqInstance.get(API_URL + "bscscan/check_wallet?wallet_address=" + data)
}

const GetAllWallets = () => {
    return reqInstance.get(API_URL + "getallWallets")
}


const GetknownWallets = (pageno, search, filter, orderColumn, orderDirection) => {
    if (orderColumn !== null && orderDirection !== null)
        return reqInstance.get(API_URL + `wallets/known?page=${pageno}&search=${search}&type=${filter}&orderColumn=${orderColumn}&orderDirection=${orderDirection}`)
    else
        return reqInstance.get(API_URL + `wallets/known?page=${pageno}&search=${search}&type=${filter}`)
}

const GetUnknownWallets = (pageno, orderColumn, orderDirection) => {
    if (orderColumn !== null && orderDirection !== null)
        return reqInstance.get(API_URL + `wallets/unknown?page=${pageno}&orderColumn=${orderColumn}&orderDirection=${orderDirection}`)
    else
        return reqInstance.get(API_URL + `wallets/unknown?page=${pageno}`)
}

const GetInternalWallets = (pageno, orderColumn, orderDirection) => {
    if (orderColumn !== null && orderDirection !== null)
        return reqInstance.get(API_URL + `wallets/internal?page=${pageno}&orderColumn=${orderColumn}&orderDirection=${orderDirection}`)
    else
        return reqInstance.get(API_URL + `wallets/internal?page=${pageno}`)
}

const GetCryptoLabels = () => {
    return reqInstance.get(API_URL + "getcryptolabels")
}

const GetCryptoAmountdue = (data) => {
    return reqInstance.get(API_URL + `cryptoamountdue?cryptoLabel=${data}`)
}

const GetBscScanAalletbyAddress = (data) => {
    return reqInstance.post(API_URL + "getbscscanwalletbyaddress", data,)
}

const GetPendingPaymentsApi = () => {
    return reqInstance.get(API_URL + "getpendingpayments")
}

const GetBlockChain = () => {
    return reqInstance.get(API_URL + "blockchainData");
}

const GetTokenCoin = (data) => {
    return reqInstance.get(API_URL + `getToken?chain=${data}`);
}

// const GenerateChart = (data) => {
//     return reqInstance.post(API_URL + "generateChart", data,);
// }

const GetDateChart = (id, wallet_address, start_date, end_date) => {
    return reqInstance.get(API_URL + `datechartdata?crypto_id=${id}&wallet_address=${wallet_address}&date_start=${start_date}&date_end=${end_date}`);
}

const GetAccounts = () => {
    return reqInstance.get(API_URL + "getaccounts", authHeader);
}

const SetAccountID = (data) => {
    return reqInstance.post(API_URL + "setaccountid", data);
}

const GetFrontEndData = (list_type) => {
    if (list_type !== undefined)
        return reqInstance.get(API_URL + "frontend_data?list_type=" + list_type);
    else
        return reqInstance.get(API_URL + "frontend_data");
}

const GetMonthlyYears = () => {
    return reqInstance.get(API_URL + "monthly/years")
}

const GetMonthlyCryptos = () => {
    return reqInstance.get(API_URL + "monthly/cryptos")
}

const MonthlyReportData = (data) => {
    return reqInstance.post(API_URL + "monthly/report", data);
}

const LogOutApi = () => {
    return reqInstance.get(API_URL + "logout", authHeader);
}

const ContactApi = (data) => {
    return reqInstance.post(API_URL + "contact", data);
}

export default {
    refreshReqInstance,
    GetknownWallets,
    GetInternalWallets,
    GetUnknownWallets,
    regsiterUser,
    loginUser,
    AccountInformations,
    ForgotPassword,
    ResetPassword,
    CheckEmail,
    CheckUsername,
    EditWalletById,
    AddWalletData,
    GetWalletLiting,
    EditWalletData,
    DeleteWalletData,
    AddUserData,
    UpdateUserName,
    UpdateEmail,
    GetUserListings,
    ChangeUserPassword,
    DeleteUser,
    NotificationApi,
    GetPaymentCryptos,
    GetPendingPayments,
    GetCryptoConverted,
    SendPayments,
    UpdatePayment,
    CancelPayment,
    DashbordApiForAccStatus,
    SubscriptionPrice,
    // GetScanWalletApi,
    GetScanByWallet,
    CheckWalletAddress,
    GetCryptoLabels,
    GetAllWallets,
    GetBscScanAalletbyAddress,
    GetCryptoAmountdue,
    GetPendingPaymentsApi,
    GetBlockChain,
    GetTokenCoin,
    // GenerateChart,
    GetDateChart,
    GetAccounts,
    SetAccountID,
    GetFrontEndData,
    GetMonthlyYears,
    GetMonthlyCryptos,
    MonthlyReportData,
    LogOutApi,
    ContactApi
}