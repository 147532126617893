import React, {lazy, Suspense} from "react";
import { AuthProvider } from "../../context/AuthContext";
import { Provider } from 'react-redux';
import store from '../../store/mainStore'

const WalletsManagement = lazy(() => import('./WalletsManagement'));

function WalletsMain () {
    return (
        <Provider store={store}>
            <AuthProvider>
                <Suspense>
                    <WalletsManagement />
                </Suspense>
            </AuthProvider>
        </Provider>
    );

}

export default WalletsMain;