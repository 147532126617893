import {createSlice} from '@reduxjs/toolkit';

export const walletSlice= createSlice({
    name: 'wallets',
    initialState: {
        listing: [],
        data: [],
        totalVal: 0,
        cryptoLabel: 'bnb',
        remaining_slot: 0,
        current_wallet: undefined,
        current_wallet_table: ''
    },
    reducers: {
        setListing: (state, action) => {
            state.listing= action.payload;
        },
        setWalletData: (state, action) => {
            state.data= action.payload;
        },
        addWalletListing: (state, action) => {
            state.listing.push(action.payload);
        },
        setCryptoLabel: (state, action) => {
            state.cryptoLabel= action.payload;
        },
        addWalletData: (state, action) => {
            state.data.push(action.payload);
        },
        setTotalVal: (state, action) => {
            state.totalVal= action.payload
        },
        addToTotalVal: (state, action) => {
            state.totalVal+= action.payload
        },
        setRemainingSlot: (state, action) => {
            state.remaining_slot= action.payload;
        },
        setCurrentWallet: (state, action) => {
            state.current_wallet= action.payload;
        },
        updateWallet: (state, action) => {
            let index1= state.listing.findIndex(item => item.ID===action.payload.ID)
            if (index1 > -1)
                state.listing[index1]= action.payload;

            let index2= state.data.findIndex(item => item.ID===action.payload)
            if (index2 > -1)
                state.data.splice(index2, 1);
        },
        deleteWallet: (state, action) => {
            let index1= state.listing.findIndex(item => item.ID===action.payload)
            if (index1 > -1)
                state.listing.splice(index1, 1);

            let index2= state.data.findIndex(item => item.ID===action.payload)
            if (index2 > -1)
                state.data.splice(index2, 1);
        },
        setCurrentWalletTable: (state, action) => {
            state.current_wallet_table= action.payload;
        }
    }
})

export const {
    setCryptoLabel,
    addWalletData,
    setTotalVal,
    addToTotalVal,
    setListing,
    setRemainingSlot,
    addWalletListing,
    setCurrentWallet,
    updateWallet,
    deleteWallet,
    setCurrentWalletTable ,
    setWalletData
} = walletSlice.actions;

export default walletSlice.reducer;