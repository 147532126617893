import React from 'react'
import { Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { FaDribbble, FaTwitter } from 'react-icons/fa';
import LogoFooter from '../../Component/Business/images/logo/new_transparent_image-02.png';

const BusinessPageFooter = () => {
    const listWrapStyle = {
        "paddingLeft": "3px",
        "listStyle": "none",
        "textAlign": "start"
    }
    return (
        <>
            <section className='footer'>
                <footer className="footer">
                    <div className="main-footer">
                        <div className="container">
                            <div className="list-footer">
                                <div className="widget-about">
                                    <div className="logo">
                                        <Link to="/">
                                            <img src={LogoFooter} alt={LogoFooter} width="100%" height="100%" />
                                        </Link>
                                    </div>
                                    <h6 className="text new-footer-cst-text p-0">Financial Management for the Best Business Outcomes
                                        <br />
                                        *DewQ Finance is not a CPA Firm.
                                    </h6>
                                </div>
                                <div className="widget-link v1">
                                    <h4 className="widget-title">Categories</h4>
                                    <ul className="list-wrap" style={listWrapStyle}>
                                        <li><AnchorLink className="menu-main active" data-toggle="tab" href="#business_home_section" role="tab">Home</AnchorLink></li>
                                        <li><AnchorLink className="menu-main active" data-toggle="tab" href="#business_choose_dewq_section" role="tab">About Us</AnchorLink></li>
                                        <li><AnchorLink className="menu-main active " data-toggle="tab" href="#business_what_we_offer_section" role="tab">Services</AnchorLink></li>
                                        <li><AnchorLink className="menu-main active" data-toggle="tab" href="#business_our_testimonial_section" role="tab">Testimonials</AnchorLink></li>
                                        <li><AnchorLink className="menu-main active" data-toggle="tab" href="#business_faq_section" role="tab">FAQ</AnchorLink></li>
                                        <li><AnchorLink className="menu-main active" data-toggle="tab" href="#business_contact_us_section" role="tab">Contact Us</AnchorLink></li>
                                    </ul>
                                </div>
                                <div className="widget-contact-form">
                                    <div className="heading">
                                        <h3>Free Consultati<span className="color">on!</span></h3>
                                    </div>
                                    <div className="fomr-contact px-5">
                                        <form action="/contact/contact-process2.php" method="post" acceptCharset="utf-8" className="form-submit contact-form wpcf7-form">
                                            <div className="form-group">
                                                <a href="https://calendly.com/dewqfinance/free-discovery-consultation" target="_blank" id="get_started_btn" name="submit" className="submit btn get_started_btn p-3">Get started</a>

                                            </div>
                                        </form>
                                    </div>
                                    <h6 className="copyright cst-copyright">Copyright 2022 <span>DewQ Finance</span>. All Rights Reserved.</h6>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="container">
                            <div className="bottom-footer">
                                <div className="content">
                                    <ul>
                                        <li><a href="#">Terms and Conditions</a></li>
                                        <li><a href="#">Privacy Policy</a></li>
                                        <li><a href="#">STATEMENT TO CONSUMERS OF DEWQ FINANCE DATA AND SERVICES</a></li>
                                    </ul>
                                </div>

                                <div className="info">
                                    <ul className="list-social">
                                        <li><a href="https://www.linkedin.com/company/dewq-finance/"><i className="fa fa-linkedin"></i></a></li>
                                        <li><a href="https://twitter.com/quincychapman"><FaTwitter /></a></li>
                                        <li><a href="#"><FaDribbble /></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </section>
        </>
    )
}

export default BusinessPageFooter