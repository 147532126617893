import {createSlice} from '@reduxjs/toolkit';

export const packageSlice= createSlice({
    name: 'package',
    initialState: {
        infos: undefined,
        subscriptionPrice: undefined,
        discount: undefined,
    },
    reducers: {
        setPackageInfos: (state, action) => {
            state.infos= action.payload
        },
        setSubscriptionPrice: (state, action) => {
            state.subscriptionPrice= action.payload
        },
        setDiscount: (state, action) => {
            state.discount= action.payload
        }
    }
})

export const { setPackageInfos, setSubscriptionPrice, setDiscount } = packageSlice.actions;

export default packageSlice.reducer;