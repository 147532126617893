import { useEffect } from "react";

const GoogleAnalytics = () => {
  window.dataLayer = window.dataLayer || [];

  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-QMWBXSJGS6";
    document.head.appendChild(script);

    function gtag() { window.dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', 'G-QMWBXSJGS6');
  }, []);

  return null;
};

export default GoogleAnalytics;
