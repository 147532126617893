import React, { Suspense } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import Navlogo from '../../assets/img/definanceLogoHomePage.png';

const HeaderForHomePage = () => {
    return (
        <>
            <header className="nav_wrapper index_nav cstm-top-zero">
                <Helmet>
                    <title>Your Solution to Defying Finance with Financial Planning, Analysis, and Custom Spreadsheets </title>
                    <meta name='description' content='DeFinance is your one stop shop for personal finance, business finance, and crypto finance. Providing financial forecasting, pro forma financial statements, financial models, and financial reporting.' />
                    <meta name='keywords' content='Crypto Tracking, crypto tracker, best crypto tracker, crypto charting, best crypto tracking app, cryptocurrency tracker, crypto tracker app, crypto wallet tracker, crypto tracking app, crypto charts app,best crypto chart app, Crypto Wallet Tracking, Wallet Tracker Crypto, best crypto tracking, cryptocurrency tracker app, crypto charting, manage multiple crypto wallets, best app to track cryptocurrency, best app to track crypto, best cryptocurrency tracker,Pro Forma, Pro Forma Financial Statements, Financial Forecast, Business Plan Financial Projections, Financial Planning and Forecasting, Financial Projections for Startups, Small Business Finance, excel sheet work, Financial Analysis, FP&A, Financial Planning and Analysis, Financial Reporting, Financial Statements, P&L Forecast, Crypto Business, Starting a Crypto Business, Business Consulting, small business consulting, small business consulting services, business partnering, reliable business partnering, cryptocurrency business, cryptocurrency start-ups, crypto start-ups, crypto consulting, crypto consulting services, cryptocurrency consulting firms, cryptocurrency consulting services' />
                </Helmet>
                <div className="container">
                    <div className="nav_inner">
                        <div className="nav_left">
                            <Link to="/">
                                <Suspense fallback={<div>Loading image...</div>}>
                                    <img className='img-fluid' src={Navlogo} alt="definancelogo" width="100%" height="100%"/>
                                </Suspense>
                            </Link>
                        </div>
                        <div className="nav_right">
                            <ul className="nav index_tabs mx-2">
                                <li className="nav-item">
                                    <AnchorLink className="nav-link" href="#anchoor_our_service">Services</AnchorLink>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="https://definance.one/blog">Blog</a>
                                </li>
                                <li className="nav-item">
                                    <AnchorLink className="nav-link" href="#anchor_our_contact" >Contact Us</AnchorLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default HeaderForHomePage