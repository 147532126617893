import React, { useState } from 'react';
import { FaTwitter, FaLinkedinIn } from 'react-icons/fa';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import LogoImg from '../../Component/Business/images/logo/new_transparent_image-02.png';
import { Helmet } from 'react-helmet';

const BusinessPageHeader = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <>
            <header className="header" id="site-header" style={{ background: "black" }}>
                <Helmet>
                    <title>DeFinance: Secure Crypto & DeFi Tracking | Best Crypto Tracking App.</title>
                    <meta name='description' content='Track, chart, and secure your crypto and DeFi finances like a pro. Enjoy anonymous sign-up, and public records tracking. Discover the best crypto tracking app.' />
                    <meta name="keywords" content="Trusted business consultants specializing in Custom Reporting, Excel Sheets. Block chain integration, focusing on Financial Planning and Analysis, Small Business Finance." />
                </Helmet>
                <div className="container">
                    <div className="main-header cst-remove-line" id="site-header-inner">
                        <div className="main-header-logo p-0" id="site-logo">
                            <a href="#">
                                <img src={LogoImg} alt={LogoImg} height="100%" className='m-0' style={{ "width": "250px" }} />
                            </a>
                        </div>
                        <div className="main-nav-header mt-4">
                            <div className="header-top">
                                <div className="slogan">
                                    <h4 className="text-slogan">Experts in Business Blockchain Integration</h4>
                                </div>
                                <ul className="list-social">
                                    <li><a href="https://www.linkedin.com/company/dewq-finance/"><FaLinkedinIn /></a></li>
                                    <li><a href="https://twitter.com/quincychapman"><FaTwitter /></a></li>
                                </ul>
                            </div>
                            <div className="mobile-button" onClick={toggleMobileMenu}>
                                <span></span>
                            </div>
                            <div className={`tf-nav ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`}>
                                <nav id="main-nav" className="main-nav">
                                    <ul id="menu-primary-menu" className="menu" >
                                        <li className="menu-item menu-item-has-children ">
                                            <AnchorLink className="menu-main active" data-toggle="tab" href="#business_home_section" role="tab" onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>Home</AnchorLink>
                                        </li>
                                        <li className="menu-item menu-item-has-children ">
                                            <AnchorLink className="menu-main active" data-toggle="tab" href="#business_choose_dewq_section" role="tab" onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>About Us</AnchorLink>
                                        </li>
                                        <li className="menu-item menu-item-has-children">
                                            <AnchorLink className="menu-main active " data-toggle="tab" href="#business_what_we_offer_section" role="tab" onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>Services</AnchorLink>
                                        </li>
                                        <li className="menu-item menu-item-has-children">
                                            <AnchorLink className="menu-main active" data-toggle="tab" href="#business_our_testimonial_section" role="tab" onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>Testimonials</AnchorLink>
                                        </li>
                                        <li className="menu-item menu-item-has-children">
                                            <AnchorLink className="menu-main active" data-toggle="tab" href="#business_faq_section" role="tab" onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>FAQ</AnchorLink>
                                        </li>
                                        <li className="menu-item menu-item-has-children">
                                            <AnchorLink className="menu-main active" data-toggle="tab" href="#business_contact_us_section" role="tab" onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>Contact Us</AnchorLink>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div className="btn-header wow   mt-4" data-wow-delay="800ms">
                            <a data-toggle="tab" href="https://calendly.com/dewqfinance/free-discovery-consultation" target="_blank" role="tab" id="free_consultation_btn" className="btn-action" style={{ "cursor": "pointer" }}>
                                <span className="more">Free Consultation</span>
                            </a>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default BusinessPageHeader;
