import { Suspense, lazy, useEffect, useState } from 'react';
import { Routes, Route } from "react-router-dom";
import RegistrationPage from './Pages/RegistrationPage';
import PersonalDashbord from './Component/Personal/PersonalDashbord';
import WalletsMain from './Component/WalletsManagement/WalletsMain';
import ReactGA from 'react-ga';
import ChartPage from './Pages/ChartPage';
import { BottamArrow } from './ReusableComponent/BottamArrow'
import NewBusinessPage from './Pages/NewBusinessPage';
import DashboardPage from './Pages/DashboardPage';
import MultiAccountsBasePage from './Pages/MultiAccountsBasePage';
import CryptoHomePage from './Pages/CryptoHomePage';
import LoginPage from './Pages/LoginPage';
import ForgotPasswordPage from './Pages/ForgotPasswordPage';
import PageNotFoundPage from './Pages/PageNotFoundPage';
import ResetPasswordPage from './Pages/ResetPasswordPage';
import MainHomePage from './Pages/MainHomePage';
import '../src/assets/css/style.css'

const TermsAndConditionModel = lazy(() => import('./Component/TermsAndConditionModel'));
const PrivacyPolicyModal = lazy(() => import('./Component/PrivacyPolicyModal'));
const SpreadsheetLandingPage = lazy(() => import('./Pages/SpreadsheetLandingPage'));
function App() {
  const TRACKING_ID = "G-QMWBXSJGS6"; // YOUR_OWN_TRACKING_ID
  ReactGA.initialize(TRACKING_ID)

  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    var loggedInUser = null
    loggedInUser = JSON.parse(localStorage.getItem("dataKey"));

    ReactGA.pageview(window.location.pathname + window.location.search);

    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Routes>
        <Route exact path='/crypto/login' element={<LoginPage />} />
        <Route exact path='/crypto' element={<CryptoHomePage />} />
        <Route exact path='/crypto/registration' element={<RegistrationPage />} />
        <Route exact path='/crypto/personaldashbord' element={<PersonalDashbord />} />
        <Route exact path='/crypto/forget-pass' element={<ForgotPasswordPage />} />
        <Route exact path='/crypto/reset_password' element={<ResetPasswordPage />} />
        <Route exact path='/crypto/multiple_accounts' element={<MultiAccountsBasePage />} />
        <Route exact path='/crypto/dashboard' element={<DashboardPage />} />
        <Route exact path="/contact" element={<MainHomePage />} />
        <Route exact path="/" element={<SpreadsheetLandingPage />} />

        {/* WalletsManagement */}
        <Route exact path='/crypto/wallets_management' element={<WalletsMain />} />
        <Route exact path="/crypto/chart" element={<ChartPage />} />
        <Route exact path="/business" element={<NewBusinessPage />} />
        <Route exact path="*" element={<PageNotFoundPage />} />

        {/* unmasked routes */}
        {/* <Route  element={<Login />} />
        <Route  element={<Home />} />
        <Route  element={<RegistrationPage />} />
        <Route  element={<PersonalDashbord />} />
        <Route  element={<ForgotPassword />} />
        <Route  element={<ResetPassword />} />
        <Route  element={<MultipleAccounts />} />
        <Route  element={<ActivationDashboard />} /> */}
        {/* <Route exact path="/charts" element={}/> */}
        {/* WalletsManagement */}
        {/* <Route exact path='/wallets_management' element={<WalletsManagement/>} /> */}
        {/* <Route  element={<Chart />} /> */}
      </Routes>
      {showTopBtn && <BottamArrow goToTop={goToTop} />}
      <Suspense fallback={<div>Loading ...</div>}>
        <TermsAndConditionModel />
      </Suspense>
      <Suspense fallback={<div>Loading ...</div>}>
        <PrivacyPolicyModal />
      </Suspense>
    </>
  );
}

export default App;