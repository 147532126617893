import {createSlice} from '@reduxjs/toolkit';

export const pageSlice= createSlice({
    name: 'page',
    initialState: {
        loading: true
    },
    reducers: {
        setPageLoading: (state, action) => {
            state.loading= action.payload
        }
    }
})

export const { setPageLoading } = pageSlice.actions;

export default pageSlice.reducer;