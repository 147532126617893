import React, { Suspense } from 'react';
import GoogleAnalytics from '../Component/GoogleAnalytics';
import HeaderForHomePage from '../Component/MainPageComponents/HeaderForHomePage';
import FinanceCards from '../Component/MainPageComponents/FinanceCards';
import ContactForm from '../Component/MainPageComponents/ContactForm';
import LiveChatButton from '../ReusableComponent/LiveChatButton';
import FooterForHomePage from '../Component/MainPageComponents/FooterForHomePage';
import ContactImg from '../assets/img/index/contact_img.svg';

const MainHomePage = () => {
    return (
        <>
            <GoogleAnalytics />
            <HeaderForHomePage />
            <FinanceCards />
            <section className="contact_wrap cst-bg-image-cls" id="anchor_our_contact">
                <div className="container">
                    <div className="contact_inner">
                        <ContactForm />
                        <div className="contact_img">
                            <img className='img-fluid' src={ContactImg} alt="ContactImage" width="100%" height="100%" />
                        </div>
                    </div>
                    <LiveChatButton />
                </div>
            </section>
            <FooterForHomePage />

        </>
    );
}

export default MainHomePage;
