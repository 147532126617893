import React, { useState } from 'react'
import UserImg from '../assets/img/user.svg'
import InvolpeImg from '../assets/img/envolpe.svg'
import PassImg from "../assets/img/pass.svg"
import LoginImg from '../assets/img/hero_img.svg'
import QuestionmarkImg from '../assets/img/question_mark.svg'
import Footer from '../Component/Footer'
import authService from '../Services/auth.service'
import swal from 'sweetalert';
import { Link } from "react-router-dom";
import CircleLoader from '../ReusableComponent/CircleLoader'
import { CommonCustomTextField, CommonSubmitButton, commonSuccess } from "../ReusableComponent/Common";
import { useForm } from "react-hook-form";
import MainHeader from "../Component/Business/components/NavBar/MainHeader";
import { EMAILPATTERN } from '../Constants/constant';


const RegistrationPage = () => {

    const [flag, setFlag] = useState(true);
    const [loaderFlag, setLoaderFlag] = useState(false)
    const [accountType, setAccountState] = useState("Personal")

    const { register, formState: { errors }, handleSubmit, getValues } = useForm();

    const usernameIsUnique = async (username) => {
        let result = await authService.CheckUsername({ username });
        return !result.data.fail;
    };
    const emailIsUnique = async (email) => {
        let result = await authService.CheckEmail({ email });
        return !result.data.fail;
    };
    const passwordConfirmation = (confirmation) => {
        let password = getValues('password');
        return password === confirmation;
    };

    const handleAccountType = (type) => {
        type === "Personal" ? setAccountState("Personal") : setAccountState("Business")
        if (type === "Business") {
            setFlag(false);
            swal({
                title: "Coming Soon",
                text: "Business Accounts are not available yet.",
                icon: "error",
                button: "OK",
                type: 'error',
                closeOnClickOutside: false,
                closeOnEsc: false,
                allowOutsideClick: false,
            }).then((value) => {
                if (value) {
                    setFlag(true)
                    setAccountState("Personal")
                }
            });
        }
    }

    const HandleCreateAccount = (data) => {
        setLoaderFlag(true)
        data.account_type = accountType;
        authService.regsiterUser(data).then((response) => {
            if (response.data.status === "success") {
                swal({
                    title: "Success",
                    text: "Your New Account is Ready",
                    icon: "success",
                    button: "Go to Dashboard",
                    type: 'success',
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                    allowOutsideClick: false,
                })
                    .then((value) => {
                        if (value) {
                            localStorage.setItem('token', response.data.data);
                            window.open("/crypto/dashboard", "_self");
                        }
                    });
            }
            else if (response.data.status === 400) {
                commonSuccess("Success", response.data.message);
            }
        }).finally(() => setLoaderFlag(false))
    }

    return (
        <>
            <MainHeader />
            <div className="bg_body bg_body_for_register_page">
                <section className="hero_wrapper">
                    <div className="container">
                        <div className="hero_inner">
                            <form className="hero_left" onSubmit={handleSubmit(HandleCreateAccount)}>
                                <h1 className="hero_title">New Account Registration</h1>
                                <p className="hero_detail">Creating a new account is simple. Just pick a username and password</p>
                                <CommonCustomTextField type="text" name="username" id="username" placeholder="Username" validate_error="This username has already been taken" rules={{ required: true, validate: usernameIsUnique }} errors={errors} register={register} img={UserImg} />
                                <CommonCustomTextField type="text" name="email" id="email" placeholder="Email Address (optional)" validate_error="This email has already been taken" rules={{ required: false, validate: emailIsUnique, pattern: EMAILPATTERN }} errors={errors} register={register} img={InvolpeImg}>
                                    <div className="question_mark">
                                        <a href='#' onClick={(e) => e.preventDefault()} data-bs-toggle="popover" data-bs-trigger="hover"
                                            title="An email address can help with account management including password recovery. However, due to our goal of decentralizing finance, it is not required to avoid providing any identifiable information. Accounts without email addresses are non-recoverable."
                                            data-bs-content="However, due to our goal of decentralizing finance, it is not required in order to avoid providing any identifying information."
                                            data-bs-placement="top" data-toggle="popover">
                                            <img src={QuestionmarkImg} alt="QuestionmarkImg" width={"100%"} height={"100%"} />
                                        </a>
                                    </div>
                                </CommonCustomTextField>
                                <CommonCustomTextField type="password" name="password" id="password" placeholder="Password" rules={{ required: true, minLength: 8, maxLength: 255 }} errors={errors} register={register} img={PassImg} />
                                <CommonCustomTextField type="password" name="password_confirmation" id="confirmPassword" placeholder="Confirm Password" rules={{ required: true, validate: passwordConfirmation }} validate_error="Password and Confirm Password does not match." errors={errors} register={register} img={PassImg} />
                                <div className="navtab_wrap">
                                    <ul className="nav nav-tabs" id="myTab" >
                                        <li className="nav-item">
                                            <button className={`nav-link ${flag ? "active" : ""}`} id="home-tab" type="button" onClick={(e) => handleAccountType("Personal")}>Personal Account</button>
                                        </li>
                                        <li className="nav-item">
                                            <button className={`nav-link ${!flag ? "active" : ""}`} id="profile-tab" type="button" onClick={(e) => handleAccountType("Business")}>Business Account</button>
                                        </li>
                                    </ul>
                                </div>
                                <p className="hero__detail">
                                    By clicking create account you are acknowledging that you have read and agree with the <a className='text-white' href='#' onClick={(e) => e.preventDefault()}
                                        data-bs-toggle="modal" data-bs-target="#term_condition"> Terms & Conditions</a> and <a className='text-white' href='#' onClick={(e) => e.preventDefault()}
                                            data-bs-toggle="modal" data-bs-target="#privacy_policy"> Privacy Policy.</a>
                                </p>
                                <CommonSubmitButton label="Create Account" className="create_btn" isSubmitting={loaderFlag} />
                                <div className="already_login">Already have an account? <Link to="/crypto/login">Sign In</Link> </div>
                            </form>
                            <div className="hero_right">
                                <img src={LoginImg} alt="LoginImg" width={"100%"} height={"100%"} />
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>       
            {loaderFlag && <CircleLoader />}
        </>
    )
}

export default RegistrationPage