import React, { Suspense, lazy } from 'react';
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import GoogleAnalytics from '../Component/GoogleAnalytics';

// Lazy-loaded components
const HeroSection = lazy(() => import('../Component/HeroSection'));
const ChooseFinance = lazy(() => import('../Component/ChooseFinance'));
const OurServices = lazy(() => import('../Component/OurServices'));
const Pricing = lazy(() => import('../Component/Pricing'));
const CryptoFaq = lazy(() => import('../Component/CryptoFaq'));
const ContactUs = lazy(() => import('../Component/ContactUs'));


const CryptoHomePage = () => {
    return (
        <>
            <GoogleAnalytics />
            <Header />
            <Suspense fallback={null}>
                <HeroSection />
            </Suspense>
            <Suspense fallback={null}>
                <ChooseFinance />
            </Suspense>
            <Suspense fallback={null}>
                <OurServices />
            </Suspense>
            <Suspense fallback={null}>
                <Pricing />
            </Suspense>
            <Suspense fallback={null}>
                <CryptoFaq />
            </Suspense>
            <Suspense fallback={null}>
                <ContactUs />
            </Suspense>
            <Footer />          
        </>
    );
}

export default CryptoHomePage;
