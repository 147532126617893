import {createSlice} from '@reduxjs/toolkit';

export const counterSlice= createSlice({
    name: 'payment',
    initialState: {
        pendingPayment: null,
        cryptos: [],
        cryptosAreLoading: false
    },
    reducers: {
        setPendingPayment: (state, action) => {
            state.pendingPayment= action.payload
        },
        setPaymentCryptos: (state, action) => {
            state.cryptos= action.payload
        },
        setCryptoAreLoading: (state, action) => {
            state.cryptosAreLoading= action.payload
        },
        updatePendingPaymentAddress: (state, action) => {
            state.pendingPayment.from= action.payload
        },
    }
})

export const { setPendingPayment, setPaymentCryptos, setCryptoAreLoading, updatePendingPaymentAddress } = counterSlice.actions;

export default counterSlice.reducer;