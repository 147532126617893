import React from "react";
import ForgotpassImg from '../assets/img/Forgot_password.svg'
import swal from 'sweetalert';
import PassImg from '../assets/img/pass.svg'
import Footer from '../Component/Footer'
import { useNavigate } from 'react-router'
import authService from "../Services/auth.service";
import { useSearchParams } from "react-router-dom";
import MainHeader from "../Component/Business/components/NavBar/MainHeader";
import { CommonCustomTextField } from "../ReusableComponent/Common";
import { useForm } from "react-hook-form";


function ResetPasswordPage() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { register, formState: { errors }, handleSubmit, getValues } = useForm();

    const passwordConfirmation = (confirmation) => {
        let password = getValues('password');
        return password === confirmation;
    };
    const resetPasswordButton = (data) => {
        var resetPaylod = {
            // username: searchParams.get('username'),
            email: searchParams.get('email'),
            token: searchParams.get('token'),
            ...data
        }
        authService.ResetPassword(resetPaylod).then((response) => {
            if (response.data.status) {
                swal({
                    title: "Success",
                    text: response.data.message,
                    icon: "success",
                    button: "Go to Login Page!",
                    type: 'success',
                })
                    .then((value) => {
                        if (value) {
                            navigate("/crypto/login")
                        }
                    });;
            }
            else if (response.data.status === 400) {
            }
        })
    }



    return (
        <>
            <MainHeader />
            <div className="bg_body bg_body_for_page">
                <section className="hero_wrapper">
                    <div className="container">
                        <div className="hero_inner">
                            <div className="hero_left">
                                <h1 className="hero_title text-md-start text-center">
                                    Reset Password?
                                </h1>
                                <p className="hero_detail text-md-start text-center">
                                    Reset Your Password Here !!
                                </p>
                                <form className="hero_left" onSubmit={handleSubmit(resetPasswordButton)}>
                                    <CommonCustomTextField type="password" name="password" id="password" placeholder="Password" rules={{ required: true, minLength: 8, maxLength: 255 }} errors={errors} register={register} img={PassImg} />
                                    <CommonCustomTextField type="password" name="password_confirmation" id="confirmPassword" placeholder="Confirm Password" rules={{ required: true, validate: passwordConfirmation }} validate_error="Password and Confirm Password does not match." errors={errors} register={register} img={PassImg} />
                                    <button className="btn create_btn flex_align" onClick={resetPasswordButton}>
                                        Reset Password
                                    </button>
                                </form>
                            </div>
                            <div className="hero_right">
                                <img src={ForgotpassImg} alt="ForgotpassImg" />
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </>

    )
}

export default ResetPasswordPage