import React, {lazy, useEffect, useState, Suspense} from 'react';
import TopdownImg from '../../assets/img/top_down.svg';
import FoxImg from '../../assets/img/fox.svg';
import BnbImg from '../../assets/img/bnb.svg';
import PinkCrypto from '../../assets/img/pink_crypto.svg';
import EthImg from '../../assets/img/eth.svg';
import HogeImg from '../../assets/img/hoge.svg';
import UniImg from '../../assets/img/uni.svg';
import BluecircleImg from '../../assets/img/blue_circle.svg';
import { useNavigate } from 'react-router';
import AddtimeImg from '../../assets/img/add_time.svg';
import CalenderImg from '../../assets/img/calender.svg';
import WalletImg from '../../assets/img/wallet.svg';
import PlusImg from '../../assets/img/plus.svg';
import PersonalHeader from './PersonalHeader';
import Footer from '../Footer';

const AddUserToAccount = lazy(() => import('./Models/AddUserToAccount'));
const Setting = lazy(() => import('./Models/Setting'));

const PersonalDashbord = () => {
    const navigate = useNavigate();
    const [displayData, setdisplayData] = useState()

    useEffect(() => {
        var saved = localStorage.getItem('dataKey')
        const initial = saved !== null ? JSON.parse(saved) : "";

        setdisplayData(initial)
        if (!initial) {
            navigate("/crypto/login")
        }
    }, [])

    return (
        <>
            <PersonalHeader showDashboardLink={true} />
            <div className=" bg_body bg_multipleAccount bg_body_for_page">
                <section className="hero_wrapper">
                    <div className="container">
                        <div className="crypto_account">
                            <div className="crypto_left">
                                <h2> Hi again,{displayData !== undefined && displayData.username} </h2>
                                <div className="active_sub">
                                    <div className="add_time">
                                        <h4>Subscription: <span>Active</span> </h4>
                                        <a href="" className="time_btn" data-bs-toggle="modal" data-bs-target="#modal_order_detail">
                                            <img src={AddtimeImg} alt="add_time.svg" />Add Time</a>
                                    </div>

                                    <div className="add_time">
                                        <p>Days Left: <span>###</span></p>
                                        <p>Expires on <span>##</span><span>/</span><span>##</span><span>/</span><span>####</span></p>
                                    </div>
                                </div>
                                <h1>Total Balance: -  </h1>
                            </div>
                            <div className="crypto_right">
                                <div className="crypto_btn monthly">
                                    <a className="flex_align" data-bs-toggle="modal" data-bs-target="#modal_monthly_report">
                                        <img src={CalenderImg} alt="CalenderImg" />
                                        Monthly Report
                                    </a>
                                </div>
                                <div className="crypto_btn wallet">
                                    <a className="flex_align"><img src={WalletImg} alt="WalletImg" />
                                        Manage Wallet Labels
                                    </a>
                                </div>
                                <div className="crypto_btn personal">
                                    <a className="flex_align" data-bs-toggle="modal" data-bs-target="#modal_personal_wallet">
                                        <img src={PlusImg} alt="plus image" />
                                        Add a PersonalWallet
                                    </a>
                                </div>
                            </div>
                            {/* </> */}
                        </div>
                        {/* <!-- wallet balance section start --> */}
                        <section className="wallet_balance">
                            <div className="wallet_main dash_main">
                                <div className="wallet_header">
                                    <div className="wallet_title">
                                        <h3>Wallet</h3>
                                        <img src={TopdownImg} alt="TopdownImage" />
                                    </div>
                                    <div className="wallet_title">
                                        <h3>Available Balance</h3>
                                        <img src={TopdownImg} alt="TopdownImage" />
                                    </div>
                                    <div className="wallet_title">
                                        <h3>Cost Basis</h3>
                                        <img src={TopdownImg} alt="TopdownImage" />
                                    </div>
                                </div>
                                {/* <!-- accordin main start  --> */}
                                <div className="wallet_content dash_content">
                                    <div className="accordion" id="accordionExample">
                                        {/* <!-- accoddin 1 start  --> */}
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    <div className="accordion_head">
                                                        <div className="crypto_img d-flex align-items-center gap-2">
                                                            <img src={FoxImg} alt="foximage" />
                                                            <h3>Metamask</h3>
                                                        </div>
                                                        <p>100,48</p>
                                                        <p>100,48</p>
                                                    </div>
                                                </button>
                                            </h2>
                                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo"
                                                data-bs-parent="#accordionExample" >
                                                <div className="accordion-body">
                                                    {/* <!-- accoddin 1-1 start  --> */}
                                                    <div className="accordion1" id="accordionExample1">
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="headingTwo_1">
                                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseTwo_1" aria-expanded="false" aria-controls="collapseTwo_1">
                                                                    <div className="accordion_head ps-md-4 py-md-2">
                                                                        <div className="crypto_img d-flex align-items-center gap-2">
                                                                            <img src={BnbImg} alt="bnbimage" />
                                                                            <h3>Metamask </h3>
                                                                        </div>
                                                                        <p>100,48 </p>
                                                                        <p>100,48</p>
                                                                    </div>
                                                                </button>
                                                            </h2>
                                                            <div id="collapseTwo_1" className="accordion1-collapse collapse" aria-labelledby="headingTwo_1"
                                                                data-bs-parent="#accordionExample1" >
                                                                <div className="accordion-body">
                                                                    {/* <!-- table here bnn --> */}
                                                                    <div className="table_wrap">
                                                                        <table className="table">
                                                                            <thead>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <th scope="row">
                                                                                        <div className="crypto_img d-flex align-items-center">
                                                                                            <img src={BnbImg} alt="bnb" />
                                                                                            <h3>BNB</h3>
                                                                                        </div>
                                                                                    </th>
                                                                                    <td>
                                                                                        <div className="available_balance">
                                                                                            <h3 className="text-center">74,48 </h3>
                                                                                            <p>Available Balance </p>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="available_balance">
                                                                                            <h3 className="text-center"> 74,48 </h3>
                                                                                            <p> Cost Basis  </p>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="available_balance">
                                                                                            <h3 className="text-center">  74,48 </h3>
                                                                                            <p> Tokens  </p>
                                                                                        </div>

                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th scope="row">
                                                                                        <div className="crypto_img d-flex align-items-center">
                                                                                            <img src={PinkCrypto} alt="pink_crypto.svg" />
                                                                                            <h3>   CLU  </h3>
                                                                                        </div>
                                                                                    </th>
                                                                                    <td>
                                                                                        <div className="available_balance">
                                                                                            <h3 className="text-center">
                                                                                                181,48
                                                                                            </h3>
                                                                                            <p>
                                                                                                Available Balance
                                                                                            </p>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="available_balance">
                                                                                            <h3 className="text-center">
                                                                                                181,48
                                                                                            </h3>
                                                                                            <p>
                                                                                                Cost Basis
                                                                                            </p>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="available_balance">
                                                                                            <h3 className="text-center">
                                                                                                181,48
                                                                                            </h3>
                                                                                            <p>
                                                                                                Tokens
                                                                                            </p>
                                                                                        </div>

                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th scope="row">
                                                                                        <div className="crypto_img d-flex align-items-center">
                                                                                            <img src={PinkCrypto} alt="pinkcrypto" />
                                                                                            <h3>
                                                                                                CLU
                                                                                            </h3>
                                                                                        </div>
                                                                                    </th>
                                                                                    <td>
                                                                                        <div className="available_balance">
                                                                                            <h3 className="text-center">
                                                                                                181,48
                                                                                            </h3>
                                                                                            <p>
                                                                                                Available Balance
                                                                                            </p>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="available_balance">
                                                                                            <h3 className="text-center">
                                                                                                181,48
                                                                                            </h3>
                                                                                            <p>
                                                                                                Cost Basis
                                                                                            </p>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="available_balance">
                                                                                            <h3 className="text-center">
                                                                                                181,48
                                                                                            </h3>
                                                                                            <p>
                                                                                                Tokens
                                                                                            </p>
                                                                                        </div>

                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="headingTwo_2">
                                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseTwo_2" aria-expanded="false" aria-controls="collapseTwo_2">
                                                                    <div className="accordion_head ps-md-4 py-md-2">
                                                                        <div className="crypto_img d-flex align-items-center gap-2">
                                                                            <img src={EthImg} alt="Eth image" />
                                                                            <h3>
                                                                                Metamask
                                                                            </h3>
                                                                        </div>
                                                                        <p>
                                                                            100,48
                                                                        </p>
                                                                        <p>
                                                                            100,48
                                                                        </p>
                                                                    </div>
                                                                </button>
                                                            </h2>
                                                            <div id="collapseTwo_2" className="accordion1-collapse collapse" aria-labelledby="headingTwo_2"
                                                                data-bs-parent="#accordionExample1" >
                                                                <div className="accordion-body">
                                                                    {/* <!-- table here  eth--> */}
                                                                    <div className="table_wrap">
                                                                        <table className="table">
                                                                            <thead>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <th scope="row">
                                                                                        <div className="crypto_img d-flex align-items-center">
                                                                                            <img src={EthImg} alt="Eth image" />
                                                                                            <h3>
                                                                                                ETH
                                                                                            </h3>
                                                                                        </div>
                                                                                    </th>
                                                                                    <td>
                                                                                        <div className="available_balance">
                                                                                            <h3 className="text-center">
                                                                                                181,48
                                                                                            </h3>
                                                                                            <p>
                                                                                                Available Balance
                                                                                            </p>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="available_balance">
                                                                                            <h3 className="text-center">
                                                                                                181,48
                                                                                            </h3>
                                                                                            <p>
                                                                                                Cost Basis
                                                                                            </p>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="available_balance">
                                                                                            <h3 className="text-center">
                                                                                                181,48
                                                                                            </h3>
                                                                                            <p>
                                                                                                Tokens
                                                                                            </p>
                                                                                        </div>

                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th scope="row">
                                                                                        <div className="crypto_img d-flex align-items-center">
                                                                                            <img src={HogeImg} alt="hog image" />
                                                                                            <h3>
                                                                                                HOGE
                                                                                            </h3>
                                                                                        </div>
                                                                                    </th>
                                                                                    <td>
                                                                                        <div className="available_balance">
                                                                                            <h3 className="text-center">
                                                                                                181,48
                                                                                            </h3>
                                                                                            <p>
                                                                                                Available Balance
                                                                                            </p>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="available_balance">
                                                                                            <h3 className="text-center">
                                                                                                181,48
                                                                                            </h3>
                                                                                            <p>
                                                                                                Cost Basis
                                                                                            </p>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="available_balance">
                                                                                            <h3 className="text-center">
                                                                                                181,48
                                                                                            </h3>
                                                                                            <p>
                                                                                                Tokens
                                                                                            </p>
                                                                                        </div>

                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th scope="row">
                                                                                        <div className="crypto_img d-flex align-items-center">
                                                                                            <img src={UniImg} alt="uni-image" />
                                                                                            <h3>
                                                                                                UNI
                                                                                            </h3>
                                                                                        </div>
                                                                                    </th>
                                                                                    <td>
                                                                                        <div className="available_balance">
                                                                                            <h3 className="text-center">
                                                                                                181,48
                                                                                            </h3>
                                                                                            <p>
                                                                                                Available Balance
                                                                                            </p>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="available_balance">
                                                                                            <h3 className="text-center">
                                                                                                181,48
                                                                                            </h3>
                                                                                            <p>
                                                                                                Cost Basis
                                                                                            </p>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="available_balance">
                                                                                            <h3 className="text-center">
                                                                                                181,48
                                                                                            </h3>
                                                                                            <p>
                                                                                                Tokens
                                                                                            </p>
                                                                                        </div>

                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <!-- accoddin 1-1 end  --> */}
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- accoddin 1 end  --> */}
                                        {/* <!-- accoddin 2 start  --> */}
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    <div className="accordion_head">
                                                        <div className="crypto_img d-flex align-items-center gap-2">
                                                            <img src={BluecircleImg} alt="Bluecircle" />
                                                            <h3>
                                                                Metamask
                                                            </h3>
                                                        </div>
                                                        <p>
                                                            100,48
                                                        </p>
                                                        <p>
                                                            100,48
                                                        </p>
                                                    </div>
                                                </button>
                                            </h2>
                                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree"
                                                data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <div className="accordion1" id="accordionExample1">
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="headingThree_1">
                                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseThree_1" aria-expanded="false" aria-controls="collapseThree_1">
                                                                    <div className="accordion_head ps-md-4 py-md-2">
                                                                        <div className="crypto_img d-flex align-items-center gap-2">
                                                                            <img src={BnbImg} alt="bnb" />
                                                                            <h3>
                                                                                Metamask
                                                                            </h3>
                                                                        </div>
                                                                        <p>
                                                                            100,48
                                                                        </p>
                                                                        <p>
                                                                            100,48
                                                                        </p>
                                                                    </div>
                                                                </button>
                                                            </h2>
                                                            <div id="collapseThree_1" className="accordion1-collapse collapse"
                                                                aria-labelledby="headingThree_1" data-bs-parent="#accordionExample1">
                                                                <div className="accordion-body">
                                                                    {/* <!-- table here bnn --> */}
                                                                    <div className="table_wrap">
                                                                        <table className="table">
                                                                            <thead>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <th scope="row">
                                                                                        <div className="crypto_img d-flex align-items-center">
                                                                                            <img src={BnbImg} alt="bnb" />
                                                                                            <h3>
                                                                                                BNB
                                                                                            </h3>
                                                                                        </div>
                                                                                    </th>
                                                                                    <td>
                                                                                        <div className="available_balance">
                                                                                            <h3 className="text-center">
                                                                                                74,48
                                                                                            </h3>
                                                                                            <p>
                                                                                                Available Balance
                                                                                            </p>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="available_balance">
                                                                                            <h3 className="text-center">
                                                                                                74,48
                                                                                            </h3>
                                                                                            <p>
                                                                                                Cost Basis
                                                                                            </p>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="available_balance">
                                                                                            <h3 className="text-center">
                                                                                                74,48
                                                                                            </h3>
                                                                                            <p>
                                                                                                Tokens
                                                                                            </p>
                                                                                        </div>

                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th scope="row">
                                                                                        <div className="crypto_img d-flex align-items-center">
                                                                                            <img src={PinkCrypto} alt="pinkCrypto" />
                                                                                            <h3>
                                                                                                CLU
                                                                                            </h3>
                                                                                        </div>
                                                                                    </th>
                                                                                    <td>
                                                                                        <div className="available_balance">
                                                                                            <h3 className="text-center">
                                                                                                181,48
                                                                                            </h3>
                                                                                            <p>
                                                                                                Available Balance
                                                                                            </p>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="available_balance">
                                                                                            <h3 className="text-center">
                                                                                                181,48
                                                                                            </h3>
                                                                                            <p>
                                                                                                Cost Basis
                                                                                            </p>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="available_balance">
                                                                                            <h3 className="text-center">
                                                                                                181,48
                                                                                            </h3>
                                                                                            <p>
                                                                                                Tokens
                                                                                            </p>
                                                                                        </div>

                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th scope="row">
                                                                                        <div className="crypto_img d-flex align-items-center">
                                                                                            <img src={PinkCrypto} alt="pinkCrypto" />
                                                                                            <h3>
                                                                                                CLU
                                                                                            </h3>
                                                                                        </div>
                                                                                    </th>
                                                                                    <td>
                                                                                        <div className="available_balance">
                                                                                            <h3 className="text-center">
                                                                                                181,48
                                                                                            </h3>
                                                                                            <p>
                                                                                                Available Balance
                                                                                            </p>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="available_balance">
                                                                                            <h3 className="text-center">
                                                                                                181,48
                                                                                            </h3>
                                                                                            <p>
                                                                                                Cost Basis
                                                                                            </p>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="available_balance">
                                                                                            <h3 className="text-center">
                                                                                                181,48
                                                                                            </h3>
                                                                                            <p>
                                                                                                Tokens
                                                                                            </p>
                                                                                        </div>

                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="headingThree_2">
                                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseThree_2" aria-expanded="false" aria-controls="collapseThree_2">
                                                                    <div className="accordion_head ps-md-4 py-md-2">
                                                                        <div className="crypto_img d-flex align-items-center gap-2">
                                                                            <img src={EthImg} alt="Eth image" />
                                                                            <h3>
                                                                                Metamask
                                                                            </h3>
                                                                        </div>
                                                                        <p>
                                                                            100,48
                                                                        </p>
                                                                        <p>
                                                                            100,48
                                                                        </p>
                                                                    </div>
                                                                </button>
                                                            </h2>
                                                            <div id="collapseThree_2" className="accordion1-collapse collapse"
                                                                aria-labelledby="headingThree_2" data-bs-parent="#accordionExample1">
                                                                <div className="accordion-body">
                                                                    {/* <!-- table here  eth--> */}
                                                                    <div className="table_wrap">
                                                                        <table className="table">
                                                                            <thead>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <th scope="row">
                                                                                        <div className="crypto_img d-flex align-items-center">
                                                                                            <img src={EthImg} alt="Eth image" />
                                                                                            <h3>
                                                                                                ETH
                                                                                            </h3>
                                                                                        </div>
                                                                                    </th>
                                                                                    <td>
                                                                                        <div className="available_balance">
                                                                                            <h3 className="text-center">
                                                                                                181,48
                                                                                            </h3>
                                                                                            <p>
                                                                                                Available Balance
                                                                                            </p>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="available_balance">
                                                                                            <h3 className="text-center">
                                                                                                181,48
                                                                                            </h3>
                                                                                            <p>
                                                                                                Cost Basis
                                                                                            </p>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="available_balance">
                                                                                            <h3 className="text-center">
                                                                                                181,48
                                                                                            </h3>
                                                                                            <p>
                                                                                                Tokens
                                                                                            </p>
                                                                                        </div>

                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th scope="row">
                                                                                        <div className="crypto_img d-flex align-items-center">
                                                                                            <img src={HogeImg} alt="hog image" />
                                                                                            <h3>
                                                                                                HOGE
                                                                                            </h3>
                                                                                        </div>
                                                                                    </th>
                                                                                    <td>
                                                                                        <div className="available_balance">
                                                                                            <h3 className="text-center">
                                                                                                181,48
                                                                                            </h3>
                                                                                            <p>
                                                                                                Available Balance
                                                                                            </p>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="available_balance">
                                                                                            <h3 className="text-center">
                                                                                                181,48
                                                                                            </h3>
                                                                                            <p>
                                                                                                Cost Basis
                                                                                            </p>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="available_balance">
                                                                                            <h3 className="text-center">
                                                                                                181,48
                                                                                            </h3>
                                                                                            <p>
                                                                                                Tokens
                                                                                            </p>
                                                                                        </div>

                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th scope="row">
                                                                                        <div className="crypto_img d-flex align-items-center">
                                                                                            <img src={UniImg} alt="uni-image" />
                                                                                            <h3>
                                                                                                UNI
                                                                                            </h3>
                                                                                        </div>
                                                                                    </th>
                                                                                    <td>
                                                                                        <div className="available_balance">
                                                                                            <h3 className="text-center">
                                                                                                181,48
                                                                                            </h3>
                                                                                            <p>
                                                                                                Available Balance
                                                                                            </p>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="available_balance">
                                                                                            <h3 className="text-center">
                                                                                                181,48
                                                                                            </h3>
                                                                                            <p>
                                                                                                Cost Basis
                                                                                            </p>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="available_balance">
                                                                                            <h3 className="text-center">
                                                                                                181,48
                                                                                            </h3>
                                                                                            <p>
                                                                                                Tokens
                                                                                            </p>
                                                                                        </div>

                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- accoddin 2 end  -->
                                        <!-- accoddin 3 start  --> */}

                                    </div>
                                </div>
                            </div>

                        </section>
                    </div>
                </section>
                {/* <!-- hero section end  --> */}

                {/* <!-- footer start  --> */}
                <Footer />
                {/* <!-- footer end  --> */}
            </div>
            {/* All Models on click show */}
            <Suspense>
                <AddUserToAccount />
            </Suspense>
            <Suspense>
                <Setting />
            </Suspense>
        </>
    )
}
export default PersonalDashbord