import LinkdinLogo from '../assets/img/linkedin.svg';
import twitterLogo from '../assets/img/twitter.svg';
import NavLogoLast from '../assets/img/nav_logo.svg';
import { useLocation } from 'react-router';
import PersonImg from "../assets/img/person.svg";

const Footer = () => {
    const location = useLocation();

    return (<>
        <div className={`defi_img ${location.pathname === "/crypto/dashboard" ? "defi_manage bg_body_for_page" : ""}
        ${location.pathname === "/" ? "defi_manage" : ""}
        ${location.pathname === "/crypto" ? "defi_manage_crypto" : ""}
        ${location.pathname === "/crypto/multiple_accounts" ? "defi_manage position-relative bg_body_for_page" : ""} 
        ${location.pathname === "/Activation_Dashboard" ? "defi_manage" : ""}
        ${location.pathname === "/crypto/chart" ? "defi_manage bg_body_for_page" : ""}
        ${location.pathname === "/crypto/login" ? "defi_manage bg_body_for_page" : ""}
        ${location.pathname === "/crypto/registration" ? "defi_manage bg_body_for_register_page" : ""}
        ${location.pathname === "/crypto/reset_password" ? "defi_manage bg_body_for_register_page" : ""}
        ${location.pathname === "/crypto/forget-pass" ? "defi_manage bg_body_for_register_page" : ""}
        ${location.pathname === "/crypto/wallets_management" ? "defi_manage bg_body_for_page" : ""} `}>

            <div className="container">
                <div className="img_wrap">
                    <img src={NavLogoLast} alt="NavLogoLast" width="100%" height="100%" />
                </div>
                {location.pathname === "/crypto/multiple_accounts" ? <>
                    <div className="person_img">
                        <img src={PersonImg} alt="PersonImg" />
                    </div>
                </> : ""}
            </div>
        </div>
        <footer className={`footer_wrapper ${location.pathname === "/crypto/dashboard" ? "footer_manage bg_body_for_page" : ""}          
        ${location.pathname === "/crypto/multiple_accounts" ? "footer_manage bg_body_for_page" : ""} 
        ${location.pathname === "/crypto" ? "defi_manage_crypto" : ""} 
        ${location.pathname === "/Activation_Dashboard" ? "footer_manage" : ""} 
        ${location.pathname === "/" ? "footer_manage" : ""} 
        ${location.pathname === "/crypto/chart" ? "footer_manage bg_body_for_page" : ""} 
        ${location.pathname === "/crypto/login" ? "footer_manage bg_body_for_page" : ""} 
        ${location.pathname === "/crypto/registration" ? "footer_manage bg_body_for_register_page" : ""} 
        ${location.pathname === "/crypto/reset_password" ? "footer_manage bg_body_for_register_page" : ""} 
        ${location.pathname === "/crypto/forget-pass" ? "footer_manage bg_body_for_register_page" : ""} 
        ${location.pathname === "/crypto/wallets_management" ? "footer_manage bg_body_for_page" : ""} `}>
            <div className="container">
                <div className="footer_inner">
                    <ul>
                        <li data-bs-toggle="modal" data-bs-target="#term_condition">TERMS AND CONDITIONS </li>
                        <li data-bs-toggle="modal" data-bs-target="#privacy_policy">PRIVACY POLICY</li>
                    </ul>
                    <div className="social_icons">
                        <div className="img_wrap">
                            <a href="https://www.linkedin.com/showcase/definance1/" target="_blank">
                                <img src={LinkdinLogo} alt="LinkdinLogo" width="100%" height="100%" />
                            </a>
                        </div>
                        <div className="img_wrap">
                            <a href="https://twitter.com/DEFINANCE_" target="_blank">
                                <img src={twitterLogo} alt="twitterLogo" width={"100%"} height={"100%"} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </>)
}


export default Footer