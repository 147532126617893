import {createSlice} from '@reduxjs/toolkit';

export const usersSlice= createSlice({
    name: 'users',
    initialState: {
        data: [],
        current_user: null
    },
    reducers: {
        setUsers: (state, action) => {
            state.data= action.payload
        },
        addUser: (state, action) => {
            state.data.push(action.payload);
        },
        removeUser: (state, action) => {
            let index= state.data.findIndex(item => item.userID===action.payload)
            if (index > -1)
                state.data.splice(index, 1);
        },
        setCurrentUser: (state, action) => {
            state.current_user= action.payload;
        }
    }
})

export const { setUsers, addUser, removeUser, setCurrentUser } = usersSlice.actions;

export default usersSlice.reducer;