import React, { useState } from "react";
import ForgotpassImg from '../assets/img/Forgot_password.svg';
import UserImg from '../assets/img/user.svg';
import authService from "../Services/auth.service";
import { Link } from "react-router-dom";
import { EMAILPATTERN } from "../Constants/constant";
import { CommonCustomTextField, commonError } from "../ReusableComponent/Common";
import { useForm } from "react-hook-form";
import InboxModel from "../Component/Personal/Models/InboxModel";
import Footer from '../Component/Footer';
import MainHeader from "../Component/Business/components/NavBar/MainHeader";
import QuestionmarkImg from '../assets/img/question_mark.svg'
import InvolpeImg from '../assets/img/envolpe.svg'
import CircleLoader from "../ReusableComponent/CircleLoader";

const ForgotPasswordPage = () => {

    const [isSubmit, setIsSubmit] = useState(false);
    const [flag, setflag] = useState(false);
    const [showMessage, setMessage] = useState("");
    const [loaderFlag, setLoaderFlag] = useState(false)

    const { register, formState: { errors }, handleSubmit } = useForm();

    const resetPasswordButton = (ResetPayload) => {
        setIsSubmit(true);
        setLoaderFlag(true)
        authService.ForgotPassword(ResetPayload).then((response) => {
            if (response.data.status) {
                setflag(true)
                setMessage(response.data.message)
                setLoaderFlag(false)
            }
            else if (response.data.status === 400) {
                setflag(false)
                setLoaderFlag(false)
                commonError("Oops!", response.data.message);
            }
        }).catch((e) => {
            setflag(false)
            setLoaderFlag(false)
        })

    }
    function handleModalClick(event) {
        event.stopPropagation();
    }

    function handleClosePopup(event) {
        setflag(false);
    }

    return (<>
        <MainHeader />
        <div className="bg_body bg_body_for_page">
            <section className="hero_wrapper">
                <div className="container">
                    <div className="hero_inner">
                        <div className="hero_left">
                            <h1 className="hero_title text-md-start text-center"> Forgot Password? </h1>
                            <p className="hero_detail text-md-start text-center">
                                Enter your Username and Password for a password reset link to be emailed to you
                            </p>
                            <form className="hero_left" onSubmit={handleSubmit(resetPasswordButton)}>
                                <CommonCustomTextField type="text" name="username" id="username" placeholder="Username" validate_error="This username has already been taken" rules={{ required: true, }} errors={errors} register={register} img={UserImg} />
                                <CommonCustomTextField type="text" name="email" id="email" placeholder="Email Address" validate_error="This email has already been taken" rules={{ required: true, pattern: EMAILPATTERN }} errors={errors} register={register} img={InvolpeImg}>
                                    <div className="question_mark">
                                        <a
                                            href='#' onClick={(e) => e.preventDefault()}
                                            data-bs-toggle="popover" data-bs-trigger="hover"
                                            title="An email address can help with account management including password recovery. However, due to our goal of decentralizing finance, it is not required to avoid providing any identifiable information. Accounts without email addresses are non-recoverable."
                                            data-bs-content="However, due to our goal of decentralizing finance, it is not required in order to avoid providing any identifying information."
                                            data-bs-placement="top" data-toggle="popover">
                                            <img src={QuestionmarkImg} alt="Questionmark" width={"100%"} height={"100%"} />
                                        </a>
                                    </div>
                                </CommonCustomTextField>

                                <button className="btn create_btn flex_align" onClick={resetPasswordButton}>
                                    Forgot Password  {/* Reset Password */}
                                </button>
                            </form>
                            <div className="already_login text-md-start text-center">
                                Don’t have an account? <Link to="/crypto/registration">Sign Up</Link>
                            </div>
                            <div className="already_login text-md-start text-center">
                                Already have an account? <Link to="/crypto/login">Sign In</Link>
                            </div>
                        </div>

                        <div className="hero_right">
                            <img src={ForgotpassImg} alt="ForgotpassImgalt" />
                        </div>
                    </div>
                </div>
            </section >
            <Footer />
        </div >

        {flag ? <>
            < InboxModel
                flag={flag}
                handleClosePopup={handleClosePopup}
                handleModalClick={handleModalClick}
                showMessage={showMessage}
            />
        </> : ""}

        {loaderFlag && <CircleLoader />}

    </>)
}

export default ForgotPasswordPage