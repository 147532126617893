import React, { useEffect } from 'react';
import { Controller } from "react-hook-form";
import swal from 'sweetalert';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import spinner from '../assets/img/spinner.svg';
import NavImg from "../assets/img/nav_logo.svg";
import Ximg from "../assets/img/X.svg";
import X2img from "../assets/img/X2.svg";
import { useTable, usePagination, useSortBy } from "react-table";
import spinner2 from '../assets/img/spinner_2.svg';
import LeftArrowImg from "../assets/img/left_arrow.svg";
import { MdOutlineChevronLeft, MdOutlineChevronRight, MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md';

const animatedComponents = makeAnimated();
const colors = {
    primary: '#77CAD2',
    primary25: 'rgba(119,202,210,0.65)',
    primary50: 'rgba(119,202,210,0.50)',
    primary75: 'rgba(119,202,210,0.25)',
}

export function CommonCustomTextField(props) {
    return (
        <>
            <div className='custom_input'>
                <div className="input_wrap mx-auto">
                    <input
                        {...props.register(props.name, props.rules)}
                        type={props.type}
                        id={props.id}
                        className="form-control"
                        placeholder={props.placeholder}
                        step={props.step}
                        readOnly={props.readonly}
                        maxLength={props.rules.maxLength}
                    />
                    <div className="img_wrap">
                        <img src={props.img} alt="images" width="100%" height="100%" />
                    </div>
                    {props.children}
                    <span className="text-white">
                        {props.errors[props.name]?.type === 'required' && "Required fields"}
                        {props.errors[props.name]?.type === 'minLength' && props.rules.minLength + " minimum characters"}
                        {props.errors[props.name]?.type === 'maxLength' && props.rules.maxLength + " max characters"}
                        {props.errors[props.name]?.type === 'pattern' && "Invalid value"}
                        {props.errors[props.name]?.type === 'validate' && props.validate_error}
                    </span>
                </div>
            </div>
        </>
    );
}

export function CommonTextField(props) {
    return (
        <>
            <div className={'input_wrap ' + props.className}>
                <label htmlFor={props.id}>
                    {props.label}
                    {props.rules.required && <sup className="text-white">*</sup>}
                </label>
                <input
                    {...props.register(props.name, props.rules)}
                    type={props.type}
                    id={props.id}
                    className="form-control"
                    placeholder={props.placeholder}
                    step={props.step}
                    readOnly={props.readonly}
                    maxLength={props.rules.maxLength}
                />
                <span className="text-white">
                    {props.errors[props.name]?.type === 'required' && "Required fields"}
                    {props.errors[props.name]?.type === 'minLength' && props.rules.minLength + " minimum characters"}
                    {props.errors[props.name]?.type === 'maxLength' && props.rules.maxLength + " max characters"}
                    {props.errors[props.name]?.type === 'pattern' && "Invalid value"}
                    {props.errors[props.name]?.type === 'validate' && props.validate_error}
                </span>
            </div>
        </>
    );
}

export function SimpleTextField(props) {
    return (
        <>
            <div className="input_wrap">
                {
                    props.label &&
                    <label htmlFor={props.id}>
                        {props.label}
                        {props.required && <sup className="text-white">*</sup>}
                    </label>
                }
                <input
                    type={props.type}
                    value={props.value}
                    id={props.id}
                    className="form-control"
                    placeholder={props.placeholder}
                    onChange={props.onChange}
                    step={props.step}
                    readOnly={props.readonly}
                    autoComplete={props.autoComplete}
                />
                {
                    props.img &&
                    <div className="img_wrap">
                        <img src={props.img} alt="props image" width="100%" height="100%" />
                    </div>
                }
            </div>
        </>
    );
}

export function CommonFileField(props) {
    return (
        <>
            <div className="input_wrap">
                <label htmlFor={props.id}>
                    {props.label}
                    {props.rules.required && <sup className="text-white">*</sup>}
                </label>
                <input
                    {...props.register(props.name, props.rules)}
                    className="form-control"
                    type="file"
                    id={props.id}
                    value={props.value}
                    accept={props.accept}
                />
                <span className="text-white">
                    {props.errors[props.name]?.type === 'required' && "Required fields"}
                </span>
            </div>
        </>
    )
}

export function CommonAreaField(props) {
    return (
        <>
            <div className="text_area" style={{ display: 'block' }}>
                <label htmlFor={props.id}>
                    {props.label}
                    {props.rules.required && <sup className="text-white">*</sup>}
                </label>
                <textarea
                    className="form-control"
                    {...props.register(props.name, props.rules)}
                    id={props.id}
                    placeholder={props.placeholder}
                    rows={props.rows}
                    style={{ maxWidth: 'inherit', width: '100%' }}
                />
                <span className="text-white">
                    {props.errors[props.name]?.type === 'required' && "Required fields"}
                    {props.errors[props.name]?.type === 'minLength' && props.rules.minLength + " minimum characters"}
                    {props.errors[props.name]?.type === 'maxLength' && props.rules.maxLength + " max characters"}
                    {props.errors[props.name]?.type === 'pattern' && "Invalid value"}
                    {props.errors[props.name]?.type === 'validate' && props.validate_error}
                </span>
            </div>
        </>
    );
}

export function CommonSelect(props) {
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontWeight: state.isSelected ? 600 : 'normal',
            fontWeight: 600
        }),
        singleValue: (provided) => ({
            ...provided,
            fontWeight: 600,
        }),
        placeholder: (provided) => ({
            ...provided,
            fontWeight: 600,
        }),
    };

    return (
        <>
            <div className={'drop_wrap ' + props.className}>
                <label htmlFor={props.id} >
                    {props.label}
                    {props.rules.required && <sup className="text-white">*</sup>}
                </label>

                <Controller
                    {...props.register(props.name, props.rules)}
                    control={props.control}
                    render={({ field }) =>
                        <Select
                            {...field}
                            options={props.options}
                            placeholder={props.placeholder}
                            isDisabled={props.isDisabled}
                            isLoading={props.isLoading}
                            isClearable={props.isClearable}
                            isSearchable={props.isSearchable}
                            isMulti={props.isMulti}
                            components={animatedComponents}
                            theme={(theme) => ({
                                ...theme,
                                border: '1px solid #ced4da',
                                borderRadius: '0.25rem',
                                colors: {
                                    ...theme.colors,
                                    ...colors
                                },
                            })}
                            styles={{ ...props.customStyle, ...customStyles }}

                        />
                    }
                />
                <span className="text-white">
                    {props.errors[props.name]?.type === 'required' && "Required fields"}
                    {props.errors[props.name]?.type === 'validate' && props.validate_error}
                </span>
            </div>
        </>
    )
}

export function SimpleSelect(props) {
    return (
        <div className={'drop_wrap ' + props.className}>
            {
                props.label &&
                <label htmlFor={props.id}>
                    {props.label}
                    {props.required && <sup className="text-white">*</sup>}
                </label>
            }
            <Select
                options={props.options}
                value={props.value}
                placeholder={props.placeholder}
                isDisabled={props.isDisabled}
                isLoading={props.isLoading}
                isClearable={props.isClearable}
                isSearchable={props.isSearchable}
                isMulti={props.isMulti}
                components={animatedComponents}
                onChange={props.onChange}
                theme={(theme) => ({
                    ...theme,
                    border: '1px solid #ced4da',
                    borderRadius: '0.25rem',
                    colors: {
                        ...theme.colors,
                        ...colors
                    },
                })}
                styles={props.customStyle}
            />
        </div>
    )
}

export function CommonCheckBox(props) {
    return (
        <div className="input_wrap">
            <div className="checkbox_wrap">
                <div className="form-group">
                    <input
                        {...props.register(props.name, props.rules)}
                        type="checkbox"
                        defaultChecked={props.checked}
                        value={props.value}
                        id={props.id}
                    />
                    <label htmlFor={props.id}>
                        {props.label}
                    </label>
                </div>
            </div>
            <span className="text-white">
                {props.errors[props.name]?.type === 'validate' && props.validate_error}
            </span>
        </div>
    )
}

export function SimpleCheckBox(props) {
    return (
        <div className="input_wrap">
            <div className="checkbox_wrap">
                <div className="form-group">
                    <input
                        type="checkbox"
                        defaultChecked={props.checked}
                        value={props.value}
                        id={props.id}
                    />
                    <label htmlFor={props.id}>
                        {props.label}
                    </label>
                </div>
            </div>
        </div>
    )
}

export function CommonModal(props) {
    return (
        <section className={`${props.sectionClass ? props.sectionClass : props.id} inner-container`}>
            <div className="modal fade" tabIndex="-1" aria-labelledby={props.id} aria-hidden="true" id={props.id} data-bs-backdrop={props.static ? 'static' : null}>
                <div className={'modal-dialog modal-dialog-centered ' + props.size + ' ' + props.className}>
                    <div className="modal-content">
                        <div className={'modal-header ' + ((!props.hideLogo || props.parentModal) ? 'justify-content-between' : '')}>
                            {
                                props.parentModal &&
                                <a href="#" data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target={'#' + props.parentModal}>
                                    <img src={LeftArrowImg} alt="leaft image" />
                                </a>
                            }
                            {
                                !props.hideLogo &&
                                <div className="img_wrap">
                                    <img src={NavImg} alt="DeFinance" width="100%" height="100%" />
                                </div>
                            }
                            <img src={props.closeDark ? X2img : Ximg} alt="Close" className={(props.hideLogo && !props.parentModal) && 'float-end'} style={{ cursor: "pointer" }} aria-label="Close" data-bs-dismiss="modal" onClick={props.onClose} ref={props.closeRef} />
                        </div>

                        <div className="modal-body">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export function commonSuccess(title, message) {
    swal({
        title: title !== undefined ? title : 'Success',
        text: message !== undefined ? message : 'Done',
        icon: 'success',
        buttonsStyling: false,
        customClass: {
            confirmButton: "btn btn-primary"
        }
    });
}

export function commonError(title, message) {
    swal({
        title: title !== undefined ? title : 'Oops!',
        text: message !== undefined ? message : 'An error has occurred. Try again later',
        icon: 'error',
        buttonsStyling: false,
        customClass: {
            confirmButton: "btn btn-danger"
        }
    });
}

export function commonWarning(title, message) {
    swal({
        title: title !== undefined ? title : 'Warning',
        text: message !== undefined ? message : 'Something went wrong',
        icon: 'warning',
        buttonsStyling: false,
        customClass: {
            confirmButton: "btn btn-warning"
        }
    });
}

export function CommonSubmitButton(props) {

    return (
        <button type="submit" disabled={props.isSubmitting} className={'btn flex_align ' + props.className}>
            {props.isSubmitting ? <img height={props.loaderHeight ? props.loaderHeight : 100} src={spinner} alt="Loading..." /> : props.label}
        </button>
    )
}

export function CommonTable(props) {
    let columns = props.columns,
        data = props.data,
        hasAction = props.hasAction;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex, pageSize, sortBy },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: props.pageIndex !== undefined ? props.pageIndex : 0,
                pageSize: props.pageSize !== undefined ? props.pageSize : 5
            },
            manualPagination: true,
            manualSortBy: true,
            pageCount: props.pageCount
        },
        useSortBy, usePagination);

    useEffect(() => {
        props.fetchData(pageIndex, pageSize, sortBy);
        if (props.setPageIndex !== undefined)
            props.setPageIndex(pageIndex);
    }, [pageIndex, sortBy])

    useEffect(() => {
        gotoPage(0);
    }, [sortBy])

    return (
        <>
            <CommonTableContent {...{ getTableProps, getTableBodyProps, headerGroups, page, prepareRow }} loading={props.loading} hasAction={hasAction} columnsLength={columns.length} />
            <CommonTablePagination
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                pageIndex={pageIndex}
                pageCount={pageCount}
                gotoPage={gotoPage}
                nextPage={nextPage}
                previousPage={previousPage}
            />
        </>
    );
}

function CommonTableContent({ getTableProps, getTableBodyProps, headerGroups, page, prepareRow, loading, hasAction, columnsLength }) {
    return (
        <div style={{ position: 'relative' }} className="table_wrap">
            <CommonTableLoading loading={loading} />
            <table {...getTableProps()} className="table table-hover mb-3">
                <thead>
                    {
                        headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {
                                    headerGroup.headers.map((column) => {
                                        return (
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())} >
                                                {column.render('Header')}
                                                {column.isSorted ? (column.isSortedDesc ? <MdKeyboardArrowDown /> : <MdKeyboardArrowUp />) : ''}
                                            </th>
                                        )
                                    })
                                }
                            </tr>
                        ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {
                        page.map(row => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()} className="bg_white">
                                    {
                                        row.cells.map((cell, index) => {
                                            if ((index + 1) === row.cells.length && hasAction) {
                                                return (
                                                    <td {...cell.getCellProps()} className="p-0">
                                                        {cell.render('Cell')}
                                                    </td>
                                                )
                                            } else {
                                                return (
                                                    <td {...cell.getCellProps()} className="align-middle">
                                                        {cell.render('Cell')}
                                                    </td>
                                                )
                                            }
                                        })}
                                </tr>
                            )
                        })
                    }
                    {
                        (!loading && page.length === 0) &&
                        <tr className="bg_white">
                            <td colSpan={columnsLength} className="text-md-start text-center ps-md-3 wallet-pagiantion">No record found!</td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    )
}

function CommonTablePagination(props) {
    return (
        <div className={'pagination-area mt-15 ' + (props.mb !== undefined ? props.mb : 'mb-50')}>
            <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-end">
                    {props.canPreviousPage ? <li className="page-item"><button type="button" className="page-link" onClick={() => props.previousPage()}>
                        <MdOutlineChevronLeft fontSize="20px" /></button></li> : ''}
                    {props.canPreviousPage ? <li className="page-item"><button type="button" onClick={() => props.previousPage()} className="page-link">{props.pageIndex}</button></li> : ''}
                    <li className="page-item active"><button type="button" className="page-link">{props.pageIndex + 1}</button></li>
                    {props.canNextPage ? <li className="page-item"><button type="button" className="page-link" onClick={() => props.nextPage()}>{props.pageIndex + 2}</button></li> : ''}
                    {(props.pageIndex + 3) <= props.pageCount ? <li className="page-item"><button type="button" className="page-link" onClick={() => props.gotoPage(props.pageIndex + 2)}>{props.pageIndex + 3}</button></li> : ''}
                    {(props.pageCount > 4 && (props.pageIndex + 3) < (props.pageCount - 1)) ? <li className="page-item"><button type="button" className="page-link dot">...</button></li> : ''}
                    {(props.pageCount > 4 && props.pageIndex + 3 < props.pageCount) ? <li className="page-item"><button type="button" className="page-link" onClick={() => props.gotoPage(props.pageCount - 1)}>{props.pageCount}</button></li> : ''}
                    {props.canNextPage ? <li className="page-item"><button type="button" className="page-link" onClick={() => props.nextPage()}>
                        <MdOutlineChevronRight fontSize="20px" />
                    </button></li> : ''}
                </ul>
            </nav>
        </div>
    )
}

function CommonTableLoading({ loading }) {
    if (loading) {
        return (
            <div className="position-absolute w-100 d-flex justify-content-center" style={{ top: '30%', zIndex: 500 }}>
                <img src={spinner2} alt="Loading..." />
            </div>
        )
    } else {
        return null;
    }
}

// export function CommonAsyncSelect(props) {

// }

// export function SimpleAsyncSelect(props) {

// }

// export function CommonDatePicker(props) {

// }

// export function SimpleDatePicker(props) {

// }