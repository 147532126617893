import React from 'react';
import ResetImg from '../../../assets/img/reset_link.svg';
import XImg from '../../../assets/img/X.svg';
import DewImg from "../../../assets/img/dew.svg";

const InboxModel = (props) => {
    return (
        <>
            <section className="modal_Checkout_inbox"  >
                <div className={`modal fade ${props.flag ? "show" : ""} sendemail`} onClick={props.handleClosePopup}>
                    <div className="modal-dialog modal-dialog-centered"  >
                        <div className="modal-content" onClick={props.handleModalClick} >
                            <div className="modal-header justify-content-between">
                                <a href="#">
                                    <div className="img_wrap">
                                        <img src={DewImg} alt="DewImg" />
                                    </div>
                                </a>
                                <img src={XImg} alt="ximg" data-bs-dismiss="modal" aria-label="Close" onClick={props.handleClosePopup} />
                            </div>
                            <div className="modal-body">
                                <h2>Check your Inbox</h2>
                                <h6>{props.showMessage}</h6>
                                <div className="img_wrap text-center">
                                    <img src={ResetImg} alt="Reset Image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default InboxModel