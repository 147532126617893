import React, { lazy, Suspense, useEffect, useState } from 'react';
import WalletImg from '../../assets/img/wallet.svg';
import PlusImg from '../../assets/img/plus.svg';
import CalenderImg from '../../assets/img/calender.svg';
import AddTimeImg from '../../assets/img/add_time.svg';
import authService from '../../Services/auth.service';
import { useSelector, useDispatch } from 'react-redux';
import { setPendingPayment, setPaymentCryptos, setCryptoAreLoading } from '../../store/paymentSlice';
import { Link } from "react-router-dom";
import { commonWarning } from '../../ReusableComponent/Common';
import { Tooltip as ReactTooltip } from "react-tooltip";
import CircleLoader from '../../ReusableComponent/CircleLoader';
import Footer from '../Footer';
import PersonalHeader from '../Personal/PersonalHeader';

const PersonalWallet = lazy(() => import('../Personal/Models/PersonalWallet'));
const MonthlyReport = lazy(() => import('../Personal/Models/MonthlyReport'));
const PaymentPage = lazy(() => import('../Payment/PaymentPage'));
const Wallets = lazy(() => import('./Wallets'));

function DashboardHeader({ paymentChecking }) {
    const pendingPayment = useSelector((state) => state.payment.pendingPayment);
    const totalVal = useSelector((state) => state.wallets.totalVal);
    const accountName = useSelector((state) => state.dashboard.accountName);
    const dispatch = useDispatch();
    const accountData = useSelector((state) => state.dashboard.accountData);

    const addTimePopupHandle2 = () => {
        dispatch(setCryptoAreLoading(true));
        authService.GetPaymentCryptos().then((response) => {
            if (response.data.status) {
                dispatch(setPaymentCryptos(response.data.data));
            }
            else if (response.data.status === 400) {
                commonWarning("Warning", response.data.message);
            }
        }).finally(() => dispatch(setCryptoAreLoading(false)))
    }

    return (
        <div className="crypto_account">
            <div className="crypto_left">
                <h2> Hi again, <span>{accountData !== undefined && accountName}</span><br /> </h2>
                <div className="active_sub">
                    <div className="add_time">
                        <h4>Subscription: <span>{accountData !== undefined && accountData?.status}</span> </h4>
                        {!paymentChecking &&
                            (pendingPayment !== null ? <>
                                <a href="#" className="time_btn" data-bs-toggle="modal" data-bs-target="#modal_pending_payment">
                                    <img src={AddTimeImg} alt="addimage" />
                                    PENDING PAYMENT
                                </a>
                            </> : <>
                                <a href="#" className="time_btn" data-bs-toggle="modal" data-bs-target="#modal_order_detail" onClick={() => addTimePopupHandle2()}>
                                    <img src={AddTimeImg} alt="addimage" />
                                    Add Time
                                </a>
                            </>)
                        }
                    </div>
                    {accountData !== undefined && accountData?.status === "Active" && <>
                        <div className="add_time">
                            <p>Days Left: <span>{accountData?.days_remaining}</span></p>
                            <p>Expires on: <span>{accountData?.subscription_end_date}</span></p>
                        </div>
                    </>}
                </div>
                {accountData !== undefined && accountData?.status === "Active" && <>
                    <h3 className='total_balance text-capitalize'>Total Balance: {totalVal !== undefined && totalVal !== null && totalVal !== "" ? '$' + (totalVal).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "-"}</h3>
                </>}
            </div>

            <div className="crypto_right">
                {
                    accountData?.status === 'Active' ? (
                        <div className="crypto_btn monthly">
                            <a href="#" className="flex_align" data-bs-toggle="modal" data-bs-target="#modal_monthly_report">
                                <img src={CalenderImg} alt="calenderimage" />
                                Monthly Report
                            </a>
                        </div>
                    ) : (
                        <div style={{ background: 'rgba(119,202,210,0.49)' }} className="crypto_btn monthly">
                            <a href="#" className="flex_align" data-tooltip-id="MonthlyReport" data-tooltip-content="Subscribe to access this feature">
                                <img src={CalenderImg} alt="calenderimage" />
                                Monthly Report
                            </a>
                            <ReactTooltip id="MonthlyReport" place="bottom" className='cstkanda' />
                        </div>
                    )
                }

                {
                    accountData?.status === 'Active' ? (
                        <div className="crypto_btn wallet">
                            <Link to="/crypto/wallets_management" className="flex_align"><img src={WalletImg} alt="WalletImg" />
                                Manage Wallet Labels
                            </Link>
                        </div>
                    ) : (
                        <div style={{ background: 'rgba(119,202,210,0.49)' }} className="crypto_btn wallet">
                            <a href="#" className="flex_align" onClick={(e) => e.preventDefault()} data-tooltip-id="ManageWallet" data-tooltip-content="Subscribe to access this feature">
                                <img src={PlusImg} alt="addimage" />
                                Manage Wallet Labels
                            </a>
                            <ReactTooltip id="ManageWallet" place="bottom" className='cstkanda' />
                        </div>
                    )
                }

                {
                    accountData?.status === 'Active' ? (
                        <div className="crypto_btn personal">
                            <a href="#" className="flex_align" onClick={(e) => e.preventDefault()} data-bs-toggle="modal" data-bs-target="#modal_personal_wallet">
                                <img src={PlusImg} alt="plusImg" />
                                Add a PersonalWallet
                            </a>
                        </div>
                    ) : (
                        <div style={{ background: 'rgba(119,202,210,0.49)' }} className="crypto_btn personal">
                            <a href="#" className="flex_align" onClick={(e) => e.preventDefault()} data-tooltip-id="PersonalWallet" data-tooltip-content="Subscribe to access this feature">
                                <img src={PlusImg} alt="plusImg" />
                                Add a PersonalWallet
                            </a>
                            <ReactTooltip id="PersonalWallet" place="bottom" className='cstkanda' />
                        </div>
                    )
                }
            </div>
        </div>
    )
}

function ActivationDashboard() {
    const accountData = useSelector((state) => state.dashboard.accountData);
    const showLoader = useSelector((state) => state.page.loading);
    const [showpage, statePage] = useState("dashboard");
    const [paymentChecking, setPaymentChecking] = useState(true);

    const dispatch = useDispatch();

    useEffect(() => {
        authService.GetPendingPaymentsApi().then((response) => {
            dispatch(setPendingPayment(response.data.data))
            setPaymentChecking(false);
        })
    }, [])

    return (
        <>
            <PersonalHeader showDashboardLink={false} />
            {(() => {
                if (showpage === "catchAll2") {
                    return (
                        <>
                            <Suspense>
                                <PaymentPage />
                            </Suspense>
                            <div>otherCase</div>
                            {/* <button onClick={(e) => statePage("catchAll")}>catchAll</button>
                            <button onClick={(e) => statePage("dashboard")}>dashboard</button> */}
                        </>
                    )
                }
                else if (showpage === "dashboard") {
                    return <>
                        <div className=" bg_body bg_multipleAccount bg_body_for_page bg_body_for_page">
                            <section className="hero_wrapper">
                                <div className="container">
                                    <DashboardHeader paymentChecking={paymentChecking} />
                                    <Suspense>
                                        <Wallets />
                                    </Suspense>
                                </div>
                            </section>
                            <Footer />
                            {accountData?.status === 'Active' && <Suspense><PersonalWallet /></Suspense>}
                        </div>
                        {accountData?.status === 'Active' && <Suspense><MonthlyReport /></Suspense>}
                    </>
                }
            })()}
            {showLoader && <CircleLoader />}
        </>
    )
}
export default ActivationDashboard