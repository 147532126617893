import '../assets/css/businessPage.css'
import { Suspense, lazy } from 'react';
import HeroVideo from '../Component/Business/video/blockchain-Finance.mp4';
import BusinessPageHeader from '../Component/NewBusinessPageComponent/BusinessPageHeader';
import BusinessPageFooter from '../Component/NewBusinessPageComponent/BusinessPageFooter';

const BusinessPageWhyChooseSection = lazy(() => import('../Component/NewBusinessPageComponent/BusinessPageWhyChooseSection'));
const BusinessPageWhatWeOfferSection = lazy(() => import('../Component/NewBusinessPageComponent/BusinessPageWhatWeOfferSection'));
const BusinessPageOurTestimonial = lazy(() => import('../Component/NewBusinessPageComponent/BusinessPageOurTestimonial'));
const BusinessPageHowItWorkSection = lazy(() => import('../Component/NewBusinessPageComponent/BusinessPageHowItWorkSection'));
const BusinessPageFaqSection = lazy(() => import('../Component/NewBusinessPageComponent/BusinessPageFaqSection'));
const BusinessPageGetInTouchSection = lazy(() => import('../Component/NewBusinessPageComponent/BusinessPageGetInTouchSection'));
const BusinessFinanceMeetSection = lazy(() => import('../Component/NewBusinessPageComponent/BusinessFinanceMeetSection'));

const NewBusinessPage = () => {
    return (
        <>
            <BusinessPageHeader />
            <section className='flat-slider home_bnr_new'>
                <div className="overlay"></div>
                <div className='thecenter'>
                    <video className='d-sm-block d-none' width={"100%"} autoPlay loop muted playsinline id="myVideo">
                        <source src={HeroVideo}></source>
                    </video>
                </div>
                <div className="home_contant">
                    <Suspense>
                        <BusinessFinanceMeetSection />
                    </Suspense>
                </div>
            </section>
            <Suspense>
                <BusinessPageWhyChooseSection />
            </Suspense>
            <Suspense>
                <BusinessPageWhatWeOfferSection />
            </Suspense>
            <Suspense>
                <BusinessPageOurTestimonial />
            </Suspense>
            <Suspense>
                <BusinessPageHowItWorkSection />
            </Suspense>
            <Suspense>
                <BusinessPageFaqSection />
            </Suspense>
            <Suspense>
                <BusinessPageGetInTouchSection />
            </Suspense>
            <BusinessPageFooter />
        </>
    )
}

export default NewBusinessPage