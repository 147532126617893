import React from "react";
import { Helmet } from "react-helmet";

const Meta = ()=>{
return(
    <Helmet>
    <title>DeFinance: Secure Crypto & DeFi Tracking | Best Crypto Tracking App.</title>
    <meta name='description' content='Track, chart, and secure your crypto and DeFi finances like a pro. Enjoy anonymous sign-up, and public records tracking. Discover the best crypto tracking app.'/>
    <meta name="keywords" content="Crypto Tracking, crypto tracker, best crypto tracker, crypto charting, best crypto tracking app, cryptocurrency tracker, crypto tracker app, crypto wallet tracker, crypto tracking app, crypto charts app,best crypto chart app, Crypto Wallet Tracking, Wallet Tracker Crypto, best crypto tracking, cryptocurrency tracker app, crypto charting, manage multiple crypto wallets, best app to track cryptocurrency, best app to track crypto, best cryptocurrency tracker"/>       
</Helmet>
)}
export default Meta;