import React from 'react'
import LinkdinLogo from '../../assets/img/linkedin.svg';
import twitterLogo from '../../assets/img/twitter.svg';
import NavLogoLast from '../../assets/img/nav_logo.svg';

const FooterForHomePage = () => {
    return (
        <>
            <div className={`defi_img defi_manage cst-bg-image-cls`}  >
                <div className="container"  >
                    <div className="img_wrap">
                        <img src={NavLogoLast} alt="NavLogoImage" width={"100%"} height={"100%"}   />
                    </div>
                </div>
            </div>
            <footer className={`footer_wrapper footer_manage cst-bg-image-cls`} >
                <div className="container">
                    <div className="footer_inner">
                        <ul>
                            <li data-bs-toggle="modal" data-bs-target="#term_condition">TERMS AND CONDITIONS </li>
                            <li data-bs-toggle="modal" data-bs-target="#privacy_policy">PRIVACY POLICY</li>
                        </ul>
                        <div className="social_icons">
                            <div className="img_wrap">
                                <a href="https://www.linkedin.com/showcase/definance1/" target="_blank">
                                    <img src={LinkdinLogo} alt="Linkdin" width={"100%"} height={"100%"}   />
                                </a>
                            </div>
                            <div className="img_wrap">
                                <a href="https://twitter.com/DEFINANCE_" target="_blank">
                                    <img src={twitterLogo} alt="twitter" width={"100%"} height={"100%"}   />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default FooterForHomePage