import React, {lazy, Suspense} from "react";
import { AuthProvider } from "../context/AuthContext";
import { Provider } from 'react-redux';
import store from '../store/mainStore';

const MultipleAccounts = lazy(() => import('../Component/Personal/MultipleAccounts'));

function MultiAccountsBasePage () {
    return (
        <Provider store={store}>
            <AuthProvider>
                <Suspense>
                    <MultipleAccounts />
                </Suspense>
            </AuthProvider>
        </Provider>
    );

}

export default MultiAccountsBasePage;