import React from "react";
import { AuthProvider } from "../context/AuthContext";
 import { Provider } from 'react-redux';
import store from '../store/mainStore'
import ActivationDashboard from "../Component/Dashboard/ActivationDashboard";

function DashboardPage () {
    return (
        <Provider store={store}>
            <AuthProvider>
                <ActivationDashboard />                
            </AuthProvider>
        </Provider>
    );    
}

export default DashboardPage;