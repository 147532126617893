import React, { useState } from 'react';
import LoginImg from '../assets/img/hero_login.svg';
import UserImg from '../assets/img/user.svg';
import passImg from '../assets/img/pass.svg';
import Footer from '../Component/Footer';
import authService from '../Services/auth.service';
import { Link } from 'react-router-dom';
import { CommonCustomTextField, CommonSubmitButton } from "../ReusableComponent/Common";
import { useForm } from "react-hook-form";
import MainHeader from '../Component/Business/components/NavBar/MainHeader';

const LoginPage = () => {
    const [showLoader, setLoader] = useState(false);
    const { register, formState: { errors }, handleSubmit } = useForm();

    const handleSignInButtonSubmitForm = (data) => {
        setLoader(true)
        authService.loginUser(data).then((response) => {
            localStorage.setItem('token', response.data.data.token);
            if (response.data.data.has_multi_account)
                window.open("/crypto/multiple_accounts", "_self");
            else
                window.open("/crypto/dashboard", "_self");
        }).finally(() => setLoader(false))
    }

    return (<>
        <MainHeader />
        <div className="bg_body bg_body_for_page">
            <section className="hero_wrapper">
                <div className="container">
                    <div className="hero_inner">
                        <div className="hero_left">
                            <form onSubmit={handleSubmit(handleSignInButtonSubmitForm)}>
                                <h1 className="hero_title text-md-start text-center">Login</h1>
                                <p className="hero_detail text-md-start text-center"> Welcome back! </p>
                                <CommonCustomTextField type="text" name="username" placeholder="Username" rules={{ required: true }} errors={errors} register={register} img={UserImg} />
                                <CommonCustomTextField type="password" name="password" placeholder="Password" rules={{ required: true }} errors={errors} register={register} img={passImg} />
                                <CommonSubmitButton label="Sign In" className="create_btn" isSubmitting={showLoader} />
                            </form>
                            <div className="already_login text-md-start text-center">
                                Don’t have an account? <Link to="/crypto/registration">Sign Up</Link>
                            </div>
                            <div className="already_login text-md-start text-center">
                                Forgot Password? <Link to="/crypto/forget-pass">Forgot Password</Link>
                            </div>
                        </div>
                        <div className="hero_right">
                            <img src={LoginImg} alt="LoginImg" width={"100%"} height={"100%"} />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
           
        </div >
    </>)
}

export default LoginPage    