import { useNavigate } from 'react-router';
import { IoCheckmarkSharp } from 'react-icons/io5';

const FinanceCards = () => {
    const navigate = useNavigate();

    const priceWrapSectionStyle = {
        "marginTop": "124px",
        "background": "linear-gradient(270deg, #000 2.15%, #484748 81.31%, #3A3839 99.27%)"
    }

    return (<>
        <section className="price_wrap pt-5 cst-bg-image-cls" id="anchor_our_price" style={priceWrapSectionStyle}>
            <div className="container" id='anchoor_our_service'>
                <h2 className='text-white pb-5'>Defying Finance ONE Area at a time</h2>
                <div className="row price_inner">
                    <div className='col-sm-4'>
                        <div className="price_box px-5 cst_price__box">
                            <div className="btn account_btn flex_align custom-cursor-none mb-4">Personal Finance</div>
                            <div className="d-flex gap-2 align-content-center mb-2">
                                <IoCheckmarkSharp className='checkmark-cst' />
                                <h1 className='heading-for-finance-card'>Budgeting Basics</h1>
                            </div>
                            <div className="d-flex gap-2 align-content-center mb-2">
                                <IoCheckmarkSharp className='checkmark-cst' />
                                <h1 className='heading-for-finance-card'>Personal Budget Template</h1>
                            </div>
                            <div className="d-flex gap-2 align-content-center">
                                <IoCheckmarkSharp className='checkmark-cst' />
                                <h1 className='heading-for-finance-card'>Budget Support & Coaching</h1>
                            </div>
                            <button className="btn buynow_btn flex_align" onClick={() => navigate("/")}>Coming Soon</button>
                        </div>
                    </div>
                    <div className='col-sm-4' >
                        <div className="price_box px-5 cst_price__box" style={{ background: "white" }}>
                            <div className="btn account_btn flex_align custom-cursor-none mb-4">Business Finance</div>
                            <div className="d-flex gap-2 align-content-center mb-2">
                                <IoCheckmarkSharp className='checkmark-cst' />
                                <h1 className='heading-for-finance-card'>Financial Analytics & Reporting</h1>
                            </div>
                            <div className="d-flex gap-2 align-content-center mb-2">
                                <IoCheckmarkSharp className='checkmark-cst' />
                                <h1 className='heading-for-finance-card'>Partnering & Coaching</h1>
                            </div>
                            <div className="d-flex gap-2 align-content-center">
                                <IoCheckmarkSharp className='checkmark-cst' />
                                <h1 className='heading-for-finance-card'>General Business Consulting</h1>
                            </div>
                            <button style={{ background: "#77cad2" }} className="btn buynow_btn flex_align" onClick={() => navigate("/business")} >START NOW</button>
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <div className="price_box cst_price__box">
                            <div className="btn account_btn flex_align custom-cursor-none mb-4">Crypto Finance</div>
                            <div className="d-flex gap-2 align-content-center mb-2">
                                <IoCheckmarkSharp className='checkmark-cst' />
                                <h1 className='heading-for-finance-card'> Combined Wallet View </h1>
                            </div>
                            <div className="d-flex gap-2 align-content-center mb-2">
                                <IoCheckmarkSharp className='checkmark-cst' />
                                <h1 className='heading-for-finance-card'>Cost Basis Reporting</h1>
                            </div>
                            <div className="d-flex gap-2 align-content-center">
                                <IoCheckmarkSharp className='checkmark-cst' />
                                <h1 className='heading-for-finance-card'>Balance Tracking</h1>
                            </div>
                            <button className="btn buynow_btn flex_align" onClick={() => navigate("/crypto")}>START NOW</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
    )
}

export default FinanceCards